import { App, Button, Modal, notification } from "antd";
import { useState } from "react";
import AddStudentForm from "./tabItems/AddStudentForm";
import DataTable from "../../components/DataTable";
import { DELETE, GET } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import { fetcher, processReq } from "../../helpers/processRequest";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/configConstants";

const Student = () => {
    const students = useSWR({method: GET, url: API_ROUTE + '/auth/student'} , fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState({});
    const navigate = useNavigate();
    const { message } = App.useApp();


    const addRecord = () => {
        setSelectedStudent({});
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
      };

    const deleteStudent = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + "/auth/student" + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }
        
        students.mutate();
    }

    const editStudent = (data) => {
        navigate('manage', {state: {...data, userRole: userRoles.student}})
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-3xl">Student Management</div>
            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Student</Button></div>

            <div>
                <DataTable 
                    columns={[
                    "id",
                    "firstName",
                    "lastName"
                    ]}
                    data={students.data}
                    isLoading={students.isLoading}
                    deleteAction={deleteStudent}
                    moreAction={editStudent}
                />
            </div>

            <Modal width={'100vw'} maskClosable={false} title="Add New Application" open={isModalOpen} onCancel={handleCancel} okButtonProps={{hidden: true}} cancelButtonProps={{hidden: true}}>
                <AddStudentForm closeApplicationView={handleCancel}/>
            </Modal>
        </div>
    );
}

export default Student;