import {GET} from "../utils/requestActionConstants";
import {API_ROUTE} from "../utils/commonConstants";
import {processReq} from "../helpers/processRequest";
import QRCode from "qrcode";
import { Document, Image, PDFViewer, Page, Text, View } from "@react-pdf/renderer";
import {useEffect, useState} from "react";

const Barcodes = props => {
    const model = '/auth/enrollment';
    const [enrollments, setEnrollments] = useState([]);

    useEffect(() => {
        loadEnrollments();
    }, []);

    const loadEnrollments = async () => {
        let cleanedData = [];
        const response = await processReq(GET, API_ROUTE + model);

        cleanedData = response.filter(cert => cert.certificateStatus > 0);

        for (let en of cleanedData) {
            en.qrCode = QRCode.toDataURL(`https://smart.clcsl.edu.lk/certificate?dist=${en.enrollmentId}`)
        }

        cleanedData = groupByFourWithTwo(cleanedData);

        setEnrollments(cleanedData);
    }

    function groupByFourWithTwo(arr) {
        const result = [];
        for (let i = 0; i < arr.length; i += 10) {
            result.push([
                [arr[i], arr[i + 1]],
                [arr[i + 2], arr[i + 3]],
                [arr[i + 4], arr[i + 5]],
                [arr[i + 6], arr[i + 7]],
                [arr[i + 8], arr[i + 9]]
            ]);
        }
        return result;
    }

    return (
        <div className="container max-w-4xl mx-auto">
            {enrollments.length === 0 && "Loading..."}
            {enrollments.length > 0 &&
            <PDFViewer width="100%" height="600px" style={{backgroundColor: '#eee'}}>
                <Document>
                    {enrollments.map((group, i) => (
                    <Page key={'in' + i} size="A4" style={{ paddingVertical: 20, paddingHorizontal: 40, fontSize: 10 }}>
                        {group.map((grouped, index) => (
                            <View key={'inner' + index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2, marginBottom: 2}}>
                                {grouped.map(enrollment => enrollment && (
                                    <View key={enrollment.id} style={{ width: '50%', border: '1px solid #ddd', padding: 10 }}>
                                        <Text>
                                            To: <Text style={{ fontWeight: 'bold' }}>Ms. {enrollment.fullName}</Text>
                                        </Text>
                                        <View style={{ paddingLeft: '15px', paddingTop: '5px' }}>
                                            <Text style={{ marginBottom: 10 }}>{enrollment.student.postAddress}</Text>
                                        </View>
                                        <View style={{ display: 'flex', paddingLeft: '13px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                            <Image src={enrollment.qrCode} style={{ width: 70, height: 70 }} />
                                            <Text>{`CLC/${enrollment.courseName.slice(0, 3).toUpperCase()}/${enrollment.legacyAdmNo}`}</Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        ))}
                    </Page>
                    ))}
                </Document>
            </PDFViewer>
            }
        </div>
    )
}

export default Barcodes;