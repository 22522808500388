import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { App, DatePicker, Input, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { getLabel } from "../utils/formatters";
import dayjs from "dayjs";


const EditInput = ({masterData, onSuccess, type, dropdownData, field, object, htmlType, secretLabel, verificationEnabled, label, disabled, dateFormat}) => {
    const { message } = App.useApp();
    const [isLabel, setIsLabel] = useState(true);
    const [value, setValue] = useState('');
    const [verificationValue, setVerficiationValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setValue(masterData)
    }, [masterData])

    const handleOnSuccess = async () => {
        setIsLabel(true);
        setIsLoading(true);
        
        try {
            if (verificationEnabled) {
                if (value === verificationValue) {
                    await onSuccess(value, field, object);
                    message.success('Record Updated!');
                } else {
                    message.error('Verification Failed! Data mismatched!');
                    setIsLabel(false);
                }
            } else {
                await onSuccess(value, field, object);
                message.success('Record Updated!');
            }
            
        } catch (error) {
            setValue(masterData);
            message.error('Data not updated! Something went wrong');
        }

        setIsLoading(false);
    }

    const handleDoubleClick = () => {
        if (!disabled) {
            setIsLabel(false);
        }
    }

    const onChangeValue = (event) => {
        if (type === 'date') {
            setValue(event)
        } else {
            setValue(event.target.value);
        }
    }

    const onChangeDropdownValue = (dropdownData, dataObject) => {
        setValue(dropdownData);
    }

    const onCancel = () => {
        setValue(masterData);
        setIsLabel(true);
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Escape') {
            onCancel();
        } else if (event.key === 'Enter') {
            handleOnSuccess();
        }
    }

    switch (type) {
        case 'dropdown':
            return (
                <div>
                    <div>
                        {
                            isLoading ? 'Loading...' : isLabel ? <Tooltip title={disabled ? "Read Only" : "Double Click To Edit"}><div className="" onDoubleClick={handleDoubleClick}>{label ? label : getLabel(value, dropdownData)}<i className="icon-pencil-square-o pl-3 text-gray-300"></i></div></Tooltip> :

                            <div className="flex w-full">
                                <Select
                                    options={dropdownData.formData ? dropdownData.formData : dropdownData}
                                    autoFocus
                                    value={value}
                                    onChange={onChangeDropdownValue}
                                    onKeyUp={handleKeyUp}
                                    style={{width: '100%'}}
                                    // onBlur={() => setIsLabel(true)}
                                />
                                <CheckCircleOutlined className="cursor-pointer px-2 text-green-700 hover:scale-125 transition ease-in-out duration-300" onClick={handleOnSuccess}/>
                                <CloseCircleOutlined className="cursor-pointer text-red-600 hover:scale-125 transition ease-in-out duration-300" onClick={onCancel}/>
                            </div>
                        }
                    </div>
                </div>
                
            )
        case 'date':
            return (
                <div>
                    <div>
                        {
                            isLabel ? isLoading ? 'Loading...' : <Tooltip title={disabled ? "Read Only" : "Double Click To Edit"}><div className="" onDoubleClick={handleDoubleClick}>{secretLabel ? '********' : dayjs(value).format(dateFormat)}<i className="icon-pencil-square-o pl-3 text-gray-300"></i></div></Tooltip> :

                            <div className="flex">
                                <DatePicker
                                    autoFocus 
                                    value={value === null ? dayjs() : dayjs(value)} 
                                    onChange={onChangeValue} 
                                    onKeyUp={handleKeyUp}
                                />
        
                                <CheckCircleOutlined className="cursor-pointer px-2 text-green-700 hover:scale-125 transition ease-in-out duration-300" onClick={handleOnSuccess}/>
                                <CloseCircleOutlined className="cursor-pointer text-red-600 hover:scale-125 transition ease-in-out duration-300" onClick={onCancel}/>
                            </div>
                        }
                    </div>
                </div>
                
            )
        default: 
            return (
                <div>
                    <div>
                        {
                            isLabel ? isLoading ? 'Loading...' : <Tooltip title={disabled ? "Read Only" : "Double Click To Edit"}><div className="" onDoubleClick={handleDoubleClick}>{secretLabel ? '********' : value}<i className="icon-pencil-square-o pl-3 text-gray-300"></i></div></Tooltip> :

                            <div className="flex">
                                <Input 
                                    autoFocus 
                                    value={value} 
                                    onChange={onChangeValue} 
                                    onKeyUp={handleKeyUp}
                                    type={htmlType}
                                    // onBlur={() => setIsLabel(true)} 
                                />
                                {
                                    verificationEnabled ?
                                        <>
                                            <div className="p-2">Verify</div>
                                            <Input 
                                                value={verificationValue} 
                                                onChange={(event) => setVerficiationValue(event.target.value)} 
                                                onKeyUp={handleKeyUp}
                                                type={htmlType}
                                                placeholder="Re-enter value here to verify"
                                            />
                                        </>
                                    : ''
                                }
                                <CheckCircleOutlined className="cursor-pointer px-2 text-green-700 hover:scale-125 transition ease-in-out duration-300" onClick={handleOnSuccess}/>
                                <CloseCircleOutlined className="cursor-pointer text-red-600 hover:scale-125 transition ease-in-out duration-300" onClick={onCancel}/>
                            </div>
                        }
                    </div>
                </div>
                
            )
    }
}

export default EditInput;