import React, { useRef, useEffect } from 'react';

const CertificateCanvas = ({ placeholder, enrollment }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Create a new image object and set the background image
        const image = new Image();
        image.src = placeholder;

        // When the image is loaded, draw it onto the canvas
        image.onload = () => {
            // Draw the background image
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            // Set the font and text alignment for the student's name
            ctx.font = '52px rosellinda';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'black';

            // Draw the student's full name at the center of the canvas
            ctx.fillText(enrollment.student.fullName, canvas.width / 2, 435);

            ctx.font = '16px Arial';
            ctx.fillText(enrollment.course.courseName === "Commerce Stream 2024" ? enrollment.course.courseName + " with AAT" : enrollment.course.courseName, canvas.width / 2, 532);
            ctx.font = '13px Arial';
            ctx.fillText(`CLC/${enrollment.course.courseName.slice(0, 3).toUpperCase()}/${enrollment.legacyAdmNo}`, canvas.width - 128, canvas.height - 79);
        };
    }, [placeholder, enrollment]);

    return (
        <canvas
            ref={canvasRef}
            width={1788 / 2.2} // Width of the canvas
            height={2523 / 2.2} // Height of the canvas
            className="m-auto flex justify-center items-center"
            style={{width: '100%', height: '100%'}}
        />
    );
};

export default CertificateCanvas;
