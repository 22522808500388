import { App, Button, Input } from "antd";
import { fetcher, processReq } from "../helpers/processRequest";
import { API_ROUTE } from "../utils/commonConstants";
import { GET, PUT } from "../utils/requestActionConstants";
import useSWR from "swr";
import { amendStatus, attendanceType } from "../utils/configConstants";
import { useContext, useEffect, useRef, useState } from "react";
import EditInput from "./EditInput";
import { AuthContext } from "../context/AuthContextProvider";
import Search from "antd/es/input/Search";
import { isEmptyString } from "../utils/validations";

const Attendance = ({ timetableDaySlotId }) => {
    const timetableDaySlotResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/studentAttendance/bySlot/' + timetableDaySlotId }, fetcher);
    const attendance = timetableDaySlotResponse.data;
    const [displayData, setDisplayData] = useState(attendance);
    const { message } = App.useApp();
    const { user } = useContext(AuthContext);
    const [adminMode, setAdminMode] = useState(false);

    const AttendanceRecord = ({ att, index }) => {
        const descriptionBox = useRef(null);
        const updateStatus = async (status) => {
            await processReq(PUT, API_ROUTE + "/auth/studentAttendance", {
                id: att.id,
                attendanceStatus: status,
                userId: user.id,
                amendStatus: amendStatus.readonly
            });
            timetableDaySlotResponse.mutate();
            message.success('Updated!')
        }

        let onUpdate = (value, field, dataObject) => {
            return new Promise(async (resolve, reject) => {
                let error = false;
                let reqObject = dataObject;

                reqObject[field] = value;

                let result = await processReq(PUT, API_ROUTE + '/auth/studentAttendance', reqObject);

                error = !(result && result.length > 0);

                if (error) {
                    reject('Failed')
                } else {
                    resolve('success');
                    timetableDaySlotResponse.mutate();
                }
            })
        }

        return (
            <div className="flex flex-row justify-between items-center">
                <div className="gap-1 px-2 grow">
                    <div className="flex gap-1">
                        <div>{index + 1}) </div>
                        {att.amendStatus === amendStatus.edittable || adminMode ?
                            <div>
                                <Button onClick={() => updateStatus(attendanceType.present)} size="small" className="bg-gradient-to-l from-green-200 to-green-300">+</Button>
                                <Button onClick={() => updateStatus(attendanceType.absent)} size="small" className="bg-gradient-to-l from-red-200 to-red-300">-</Button>
                            </div>
                            : ''
                        }
                        {att.enrollmentId} - {att.student.fullName}
                    </div>
                    <div className="pt-1 pl-1">
                        <EditInput
                            masterData={att.description}
                            onSuccess={onUpdate}
                            field={'description'}
                            object={att}
                        />
                    </div>
                </div>
                <div>
                    <div className=" px-1 rounded bg-gradient-to-l from-blue-200 to-blue-300 text-xsm font-bold">{att.attendanceStatus}</div>
                    <div>Marked By: {att.user?.userName}</div>
                </div>
            </div>
        )
    }

    const updateValue = (value) => {
        if (isEmptyString(value.target.value)) {
            setDisplayData(attendance)
        } else {
            const result = displayData.filter(dt => dt.student.fullName.toLowerCase().includes(value.target.value.toLowerCase()));

            setDisplayData(result);
        }
    }

    useEffect(() => {
        setDisplayData(attendance);
    }, [attendance])
    return (
        <div>
            <div className="text-xl font-bold pt-5 border-b">Student Attendance</div>
            <div className="text-right pb-5">
                <div className="flex gap-2 text-lg">
                    <div>Total: {attendance?.length} </div>
                    <div>Present: {attendance?.filter(att => att.attendanceStatus === attendanceType.present).length} </div>
                    <div>Absent: {attendance?.filter(att => att.attendanceStatus === attendanceType.absent).length}</div>
                </div>
                {user.isAdmin ? 
                    <Button size="small" onClick={() => setAdminMode(a => !a)}>{adminMode ? "Disable Admin Mode" : "Enable Admin Mode"}</Button>
                    : <></>
                }
            </div>
            <div className="pb-4">
                <Search onChange={updateValue} />
            </div>
            {displayData?.map((att, index) => {
                return (
                    <div key={att.id} className="border p-4 rounded">
                        <AttendanceRecord att={att} index={index} />
                    </div>
                )
            })}
        </div>
    )
}

export default Attendance;