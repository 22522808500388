import { App, Button, Form, Input, Modal, Switch } from "antd";
import { fetcher, processReq } from "../../helpers/processRequest";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import DataTable from "../../components/DataTable";
import { messages } from "../../utils/configConstants";
import Marquee from "react-fast-marquee";

const NoticeBoard = () => {
    const result = useSWR({method: GET, url: API_ROUTE + '/auth/notice'} , fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const [noticeString, setNoticeString] = useState('');

    useEffect(() => {
        resetForm();
    }, [])

    useEffect(() => {
        handleNoticeUpdate();
    }, [result.data])   

    const handleNoticeUpdate = () => {
        const noticesToShow = [];

        const mappedNotices = result.data?.filter((notice) => {
            if (notice.isVisible) {
                noticesToShow.push(notice.content);
            }
        })

        setNoticeString(noticesToShow.reverse().join(' - '));
    }

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + '/auth/notice/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }
        
        result.mutate();
    }

    const editRecord = (record) => {
        let object = {
            ...record,
        }

        setSelectedRecord(object);
        form.setFieldsValue(object);
        setIsModalOpen(true);
    }

    const addRecord = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedRecord.id) {
            // console.log({...data, id: selectedRecord.id})
            await processReq(PUT, API_ROUTE + '/auth/notice', {...data, id: selectedRecord.id});
            result.mutate();
            message.success('Updated!')
        } else {
            await processReq(POST, API_ROUTE + '/auth/notice', data);
            result.mutate();
            message.success('Saved!')
        }
        
        handleCancel();
    }

    const resetForm = () => {
        setSelectedRecord({})
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-2xl">Notice Board Management</div>
            <div className=" bg-slate-200 rounded"><Marquee>{noticeString}</Marquee></div>
            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Notice</Button></div>
            <div>
                <DataTable 
                    columns={[
                    "id",
                    "content",
                    "isVisible"
                    ]}
                    data={result.data}
                    isLoading={result.isLoading}
                    deleteAction={deleteRecord}
                    editAction={editRecord}
                />
            </div>

            <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{hidden: true}} cancelButtonProps={{hidden: true}}>
                <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                    <Form.Item label='Notice Content' name={'content'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter text"/>
                    </Form.Item>
                    <Form.Item label="Show Notice" name="isVisible" rules={[{required: true, message: messages.requiredMessage}]}>
                        <Switch />
                    </Form.Item>
                        
                    <Form.Item className="text-right">
                        <Button htmlType="submit">Save Record</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default NoticeBoard;