export const lecturers = [
    {
        id: 1,
        name: 'Thanish',
        value: 'Thanish',
        label: 'Thanish',
    },
    {
        id: 2,
        name: 'Riswan',
        value: 'Riswan',
        label: 'Riswan'
    },
    {
        id: 3,
        name: 'Rismi',
        value: 'Rismi',
        label: 'Rismi'
    }
]

export const subjectsData = [
    {
        id: 1,
        name: 'Chemistry',
        value: 'Chemistry',
        label: 'Chemistry',
        lecturers: [lecturers[0], lecturers[1]]
    },
    {
        id: 2,
        name: 'Physics',
        value: 'Physics',
        label: 'Physics',
        lecturers: [lecturers[1], lecturers[2]]
    }
]

export const courses = [
    {
        id: 1,
        name: 'Science',
        year: '2023',
        value: 'Science Stream 2023',
        label: 'Science Stream 2023',
    },
    {
        id: 2,
        name: 'Commerce',
        year: '2023',
        value: 'Commerce Stream 2023',
        label: 'Commerce Stream 2023',
    },
    {
        id: 3,
        name: 'Science',
        year: '2024',
        value: 'Science Stream 2024',
        label: 'Science Stream 2024',
    }
]

export const timetables = [
    {
        "id": 1,
        "startDate": "2023-12-01T17:01:31.658Z",
        "endDate": "2023-12-02T17:01:31.658Z",
        "description": "Science Stream 2023",
        "timeTableDays": [
            {
                "id": 0,
                "date": "2023-12-01T17:01:31.658Z",
                "title": "2023-12-01 Friday",
                "timeTableDaySlots": [
                    {
                        "from": "2023-12-01T00:00:00.565Z",
                        "to": "2023-12-01T02:00:00.565Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 2,
                            "name": "Riswan",
                            "value": "Riswan",
                            "label": "Riswan"
                        }
                    }
                ]
            },
            {
                "id": 1,
                "date": "2023-12-02T17:01:31.658Z",
                "title": "2023-12-02 Saturday",
                "timeTableDaySlots": [
                    null,
                    {
                        "from": "2023-12-01T02:00:02.418Z",
                        "to": "2023-12-01T02:30:02.418Z",
                        "subject": {
                            "id": 2,
                            "name": "Physics",
                            "value": "Physics",
                            "label": "Physics",
                            "lecturers": [
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                },
                                {
                                    "id": 3,
                                    "name": "Rismi",
                                    "value": "Rismi",
                                    "label": "Rismi"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 3,
                            "name": "Rismi",
                            "value": "Rismi",
                            "label": "Rismi"
                        }
                    },
                    {
                        "from": "2023-12-01T02:30:02.600Z",
                        "to": "2023-12-01T04:30:02.600Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 1,
                            "name": "Thanish",
                            "value": "Thanish",
                            "label": "Thanish"
                        }
                    }
                ]
            }
        ],
        "course": {
            "id": 1,
            "name": "Science",
            "year": "2023",
            "value": "Science Stream 2023",
            "label": "Science Stream 2023"
        }
    },
    {
        "id": 2,
        "startDate": "2023-12-01T17:47:11.894Z",
        "endDate": "2023-12-02T17:47:11.894Z",
        "description": "Commerce Stream 2023",
        "timeTableDays": [
            {
                "id": 0,
                "date": "2023-12-01T17:47:11.894Z",
                "title": "2023-12-01 Friday",
                "timeTableDaySlots": [
                    null,
                    null,
                    null,
                    {
                        "from": "2023-12-01T04:45:36.272Z",
                        "to": "2023-12-01T06:45:36.272Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 2,
                            "name": "Riswan",
                            "value": "Riswan",
                            "label": "Riswan"
                        }
                    },
                    {
                        "from": "2023-12-01T07:15:36.957Z",
                        "to": "2023-12-01T08:30:36.957Z",
                        "subject": {
                            "id": 2,
                            "name": "Physics",
                            "value": "Physics",
                            "label": "Physics",
                            "lecturers": [
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                },
                                {
                                    "id": 3,
                                    "name": "Rismi",
                                    "value": "Rismi",
                                    "label": "Rismi"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 3,
                            "name": "Rismi",
                            "value": "Rismi",
                            "label": "Rismi"
                        }
                    }
                ]
            },
            {
                "id": 1,
                "date": "2023-12-02T17:47:11.894Z",
                "title": "2023-12-02 Saturday",
                "timeTableDaySlots": [
                    {
                        "from": "2023-12-01T00:00:25.066Z",
                        "to": "2023-12-01T02:00:25.066Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 1,
                            "name": "Thanish",
                            "value": "Thanish",
                            "label": "Thanish"
                        }
                    }
                ]
            },
            {
                "id": 0,
                "date": "2023-12-01T17:47:11.894Z",
                "title": "2023-12-01 Friday",
                "timeTableDaySlots": [
                    null,
                    null,
                    null,
                    {
                        "from": "2023-12-01T04:45:36.272Z",
                        "to": "2023-12-01T06:45:36.272Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 2,
                            "name": "Riswan",
                            "value": "Riswan",
                            "label": "Riswan"
                        }
                    },
                    {
                        "from": "2023-12-01T07:15:36.957Z",
                        "to": "2023-12-01T08:30:36.957Z",
                        "subject": {
                            "id": 2,
                            "name": "Physics",
                            "value": "Physics",
                            "label": "Physics",
                            "lecturers": [
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                },
                                {
                                    "id": 3,
                                    "name": "Rismi",
                                    "value": "Rismi",
                                    "label": "Rismi"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 3,
                            "name": "Rismi",
                            "value": "Rismi",
                            "label": "Rismi"
                        }
                    }
                ]
            },
            {
                "id": 1,
                "date": "2023-12-02T17:47:11.894Z",
                "title": "2023-12-02 Saturday",
                "timeTableDaySlots": [
                    {
                        "from": "2023-12-01T00:00:25.066Z",
                        "to": "2023-12-01T02:00:25.066Z",
                        "subject": {
                            "id": 1,
                            "name": "Chemistry",
                            "value": "Chemistry",
                            "label": "Chemistry",
                            "lecturers": [
                                {
                                    "id": 1,
                                    "name": "Thanish",
                                    "value": "Thanish",
                                    "label": "Thanish"
                                },
                                {
                                    "id": 2,
                                    "name": "Riswan",
                                    "value": "Riswan",
                                    "label": "Riswan"
                                }
                            ]
                        },
                        "lecturer": {
                            "id": 1,
                            "name": "Thanish",
                            "value": "Thanish",
                            "label": "Thanish"
                        }
                    }
                ]
            }
        ],
        "course": {
            "id": 2,
            "name": "Commerce",
            "year": "2023",
            "value": "Commerce Stream 2023",
            "label": "Commerce Stream 2023"
        }
    },
]

export const achivements = [
    {
      "admission": 2646,
      
      "otherAchivements": "Participated in quiz competition"
    },
    {
      "admission": 241003,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Prefect"
    },
    {
      "admission": 241006,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 241008,
      
      "otherAchivements": "Prefect"
    },
    {
      "admission": 241012,
      
      "otherAchivements": "Member of media unit"
    },
    {
      "admission": 241015,
      
      "otherAchivements": "Member of muslim majlis"
    },
    {
      "admission": 241016,
      
      "otherAchivements": "Member of media unit"
    },
    {
      "admission": 241017,
      
      "otherAchivements": "Senior executive prefect,Member of ELU,Participated in quiz competition"
    },
    {
      "admission": 242003,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Deputy head prefect"
    },
    {
      "admission": 242007,
      
      "otherAchivements": "Member of media unit,Participated in quiz competition"
    },
    {
      "admission": 242008,
      
      "otherAchivements": "Nil"
    },
    {
      "admission": 242010,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242011,
      "academicAchivements": "Ranked in the top 10,Physics top scorer in fifth term",
      
    },
    {
      "admission": 242012,
      
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242013,
      
      "otherAchivements": "Vice president of muslim majlis"
    },
    {
      "admission": 242015,
      
      
    },
    {
      "admission": 242016,
      
      "otherAchivements": "Member of muslim majlis,Member of media unit"
    },
    {
      "admission": 242017,
      
      "otherAchivements": "Participated in quiz competition"
    },
    {
      "admission": 242018,
      
      "otherAchivements": "Secretary of health club"
    },
    {
      "admission": 242022,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242024,
      
      "otherAchivements": "Member of media unit,Member of ELU"
    },
    {
      "admission": 242029,
      
      "otherAchivements": "Member of science society"
    },
    {
      "admission": 242036,
      
      "otherAchivements": "Member of muslim majlis"
    },
    {
      "admission": 242037,
      
      "otherAchivements": "Participated in quiz competition,Member of muslim majlis"
    },
    {
      "admission": 242042,
      
      "otherAchivements": "Member of muslim majlis"
    },
    {
      "admission": 242047,
      
      "otherAchivements": "Member of media unit,Member of ELU,Participated in quiz competition"
    },
    {
      "admission": 242050,
      
      "otherAchivements": "Member of media unit"
    },
    {
      "admission": 242053,
      
      "otherAchivements": "Member of health club,Participated in quiz competition"
    },
    {
      "admission": 242057,
      
      "otherAchivements": "Participated in quiz competition,Member of health club"
    },
    {
      "admission": 242058,
      
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242060,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242061,
      
      
    },
    {
      "admission": 242062,
      
      
    },
    {
      "admission": 242065,
      
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242066,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242068,
      
      "otherAchivements": "Member of muslim majlis"
    },
    {
      "admission": 242070,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242072,
      
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242075,
      
      "otherAchivements": "Class Monitor,President of Science Society"
    },
    {
      "admission": 242088,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Member of science society"
    },
    {
      "admission": 242094,
      
      "otherAchivements": "Treasurer of science society"
    },
    {
      "admission": 242095,
      
      "otherAchivements": "Vice president of media unit"
    },
    {
      "admission": 242097,
      
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242098,
      
      "otherAchivements": "Member of muslim majlis"
    },
    {
      "admission": 242105,
      
      "otherAchivements": "Prefect"
    },
    {
      "admission": 242110,
      
      "otherAchivements": "Member of health club,Star student"
    },
    {
      "admission": 242111,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242113,
      
      "otherAchivements": "Member of science society,Member of ELU"
    },
    {
      "admission": 242118,
      
      "otherAchivements": "Member of health club"
    },
    {
      "admission": 242119,
      
      "otherAchivements": "Secretary of library club"
    },
    {
      "admission": 242121,
      
      
    },
    {
      "admission": 242123,
      
      "otherAchivements": "Member of science society,Participated in quiz competition"
    },
    {
      "admission": 242140,
      
      
    },
    {
      "admission": 242145,
      
      
    },
    {
      "admission": 242148,
      
      
    },
    {
      "admission": 242149,
      
      
    },
    {
      "admission": 242153,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Member of science society"
    },
    {
      "admission": 242155,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242156,
      
      
    },
    {
      "admission": 242019,
      "academicAchivements": "3rd place in physics heighest Marks",
      "otherAchivements": "Senior executieve prefect"
    },
    {
      "admission": 242020,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242021,
      
      "otherAchivements": "Treasurer of media unit"
    },
    {
      "admission": 242026,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Vice President of ELU"
    },
    {
      "admission": 242031,
      
      
    },
    {
      "admission": 242052,
      
      
    },
    {
      "admission": 242078,
      "academicAchivements": "Ranked in the top 10",
      "otherAchivements": "Member of science society"
    },
    {
      "admission": 242083,
      
      
    },
    {
      "admission": 242085,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242086,
      
      
    },
    {
      "admission": 242100,
      
      "otherAchivements": "Participated in quiz competition,Member of muslim majlis"
    },
    {
      "admission": 242116,
      
      "otherAchivements": "Member of health club"
    },
    {
      "admission": 242125,
      
      
    },
    {
      "admission": 242126,
      
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 242127,
      
      
    },
    {
      "admission": 242135,
      
      "otherAchivements": "Member of science society"
    },
    {
      "admission": 242147,
      
      
    },
    {
      "admission": 242150,
      
      
    },
    {
      "admission": 242154,
      
      
    },
    {
      "admission": 241013,
      
      
    },
    {
      "admission": 2527,
      "academicAchivements": "2nd heighest in physics paper 5th term,1st place in 2nd & 3rd term,2nd place in 1st and 4th term",
      "otherAchivements": "Member of science society"
    },
    {
      "admission": 2611,
      
      
    },
    {
      "admission": 242101,
      
      
    },
    {
      "admission": 243001,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "President of commerce society,Member of ELU,Head Prefect"
    },
    {
      "admission": 243003,
      "academicAchivements": "AAT Passed Finalist,Island third in AAT level 2 and 3",
      "otherAchivements": "President of ELU,Member of muslim majlis,Member of library club"
    },
    {
      "admission": 243009,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Treasurer of library club"
    },
    {
      "admission": 243011,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Senior Executive Prefect,President of media unit,Member of ELU"
    },
    {
      "admission": 243013,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of library society"
    },
    {
      "admission": 243014,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Prefect,Member of commerce society"
    },
    {
      "admission": 243015,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Prefect,Member of health club"
    },
    {
      "admission": 243016,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Prefect,Treasurer of health club"
    },
    {
      "admission": 243017,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "President of muslim majlis"
    },
    {
      "admission": 243019,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of muslim majlis,Member of commerce society,Member of library"
    },
    {
      "admission": 243021,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of  media unit"
    },
    {
      "admission": 243022,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of  commerce society"
    },
    {
      "admission": 243025,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Prefect,Member of commerce society"
    },
    {
      "admission": 243031,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Secretary of muslim majlis,Vice President of ELU"
    },
    {
      "admission": 243033,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Prefect,President  of health club"
    },
    {
      "admission": 243038,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of health club"
    },
    {
      "admission": 243039,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of commerce society,Member of ELU"
    },
    {
      "admission": 243041,
      "academicAchivements": "Complete AAT Level 1",
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 243042,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of health club"
    },
    {
      "admission": 243044,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 243046,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of muslim majlis"
    },
    {
      "admission": 243047,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243050,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Perfect,Member of commerce society"
    },
    {
      "admission": 243054,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Vice President of library club,Member of commerce society"
    },
    {
      "admission": 243055,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of media unit"
    },
    {
      "admission": 243062,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Secretary of media unit"
    },
    {
      "admission": 243065,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Nil"
    },
    {
      "admission": 243066,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Vice Secretary of ELU,Member of commerce society"
    },
    {
      "admission": 243071,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243072,
      "academicAchivements": "Completed AAT level 1",
      "otherAchivements": "Member of health club"
    },
    {
      "admission": 243075,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243078,
      
      
    },
    {
      "admission": 243082,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243084,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Nil"
    },
    {
      "admission": 243090,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of media unit"
    },
    {
      "admission": 243091,
      "academicAchivements": "Completed level 1",
      "otherAchivements": "Member of health club"
    },
    {
      "admission": 243093,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Nil"
    },
    {
      "admission": 243095,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Prefect,Vice President of commerce society"
    },
    {
      "admission": 243097,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Secretary of commerce society,Member of muslim majlis,Member of ELU"
    },
    {
      "admission": 243098,
      "academicAchivements": "Completed AAT level 1and 2",
      "otherAchivements": "Member of library club"
    },
    {
      "admission": 243105,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Treasurer of muslim majlis,Member of media unit,Member of ELU"
    },
    {
      "admission": 243108,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243110,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Prefect,Treasurer of commerce society"
    },
    {
      "admission": 243111,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243113,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243114,
      "academicAchivements": "AAT Passed Finalist",
      "otherAchivements": "Member of ELU"
    },
    {
      "admission": 243115,
      "academicAchivements": "AAT Passed Finalist",
      
    },
    {
      "admission": 243116,
      "academicAchivements": "Completed AAT level 1 and 2",
      
    },
    {
      "admission": 243122,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Member of commerce society"
    },
    {
      "admission": 243126,
      "academicAchivements": "Completed AAT level 1 and 2",
      "otherAchivements": "Prefect"
    },
    {
      "admission": 243067,
      "academicAchivements": "Completed AAT level 1 and 2",
      
    },
    {
      "admission": 242130,
      "academicAchivements": "AAT Passed Finalist",
      
    }
  ]