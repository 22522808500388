import useSWR, { mutate } from "swr";
import { GET, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import { enrollmentStatus } from "../../../utils/configConstants";
import EditInput from "../../../components/EditInput";
import { Input } from "antd";

const EnrollmentForm = ({ formData }) => {
    const courses = useSWR({ method: GET, url: API_ROUTE + '/auth/course' }, fetcher);
    const enrollmentRequest = useSWR({ method: GET, url: API_ROUTE + '/auth/enrollment/dist/' + formData.enrollmentId }, fetcher);
    const enrollment = enrollmentRequest.data;

    let onSuccess = (value, field) => {
        return new Promise(async (resolve, reject) => {
            let error = false;
            let reqObject = enrollment;

            reqObject[field] = value;

            let result = await processReq(PUT, API_ROUTE + '/auth/enrollment', reqObject);

            error = !(result && result.length > 0);

            if (error) {
                reject('Failed')
            } else {
                resolve('success');
                enrollmentRequest.mutate();
            }
        })
    }

    return (
        <div>
            {
                enrollmentRequest.isLoading ? 'Loading' :

                    <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <div className="text-xl pb-3">Enrollment Details</div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Enrollment Status</div> : <div className="pl-2">
                                    <EditInput
                                        type={'dropdown'}
                                        masterData={enrollment.enrollmentStatus}
                                        onSuccess={onSuccess}
                                        dropdownData={enrollmentStatus}
                                        field={'enrollmentStatus'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Course</div> : <div className="pl-2">
                                    <EditInput
                                        type={'dropdown'}
                                        masterData={enrollment.courseId}
                                        onSuccess={onSuccess}
                                        dropdownData={courses.isLoading ? [] : courses.data}
                                        field={'courseId'}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Admission Fee</div> : <div className="pl-2">
                                    <EditInput
                                        masterData={enrollment.admissionFee}
                                        onSuccess={onSuccess}
                                        field={'admissionFee'}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Class Fee</div> : <div className="pl-2">
                                    <EditInput
                                        masterData={enrollment.course.classFee}
                                        onSuccess={onSuccess}
                                        field={'admissionFee'}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Accomodation Fee Fee</div> : <div className="pl-2">
                                    <EditInput
                                        masterData={enrollment.course.accomodationFee}
                                        onSuccess={onSuccess}
                                        field={'accomodationFee'}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Monthly Fee</div> : <div className="pl-2">
                                    <EditInput
                                        masterData={enrollment.monthlyFee}
                                        onSuccess={onSuccess}
                                        field={'admissionFee'}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Payment Start Date</div> : <div className="pl-2">
                                    <EditInput
                                        type='date'
                                        masterData={enrollment.paymentStartDate}
                                        onSuccess={onSuccess}
                                        field={'paymentStartDate'}
                                        object={enrollment}
                                        dateFormat="DD-MM-YYYY"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Enrollment Date</div> : <div className="pl-2">
                                    <EditInput
                                        type='date'
                                        masterData={enrollment.enrolledOn}
                                        onSuccess={onSuccess}
                                        field={'enrolledOn'}
                                        object={enrollment}
                                        dateFormat="DD-MM-YYYY"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default EnrollmentForm;
