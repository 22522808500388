import { GET } from "../utils/requestActionConstants";
import { API_ROUTE } from "../utils/commonConstants";
import { processReq } from "../helpers/processRequest";
import QRCode from "qrcode";
import { Document, Font, Image, PDFViewer, Page, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import logo from '../assets/img/logo.png';
import signatures from '../assets/img/signatures.png';
import font from '../assets/fonts/ScriptMTBold.ttf';
import dayjs from "dayjs";
import { achivements } from "../utils/dummyData";

// Font.register({
//     family: "clcFontStyle",
//     src: font,
//     // Optionally add different styles if available (bold, italic, etc.)
//     // fonts: [
//     //   { src: "/path/to/your/custom-font.ttf", fontWeight: "normal" },
//     //   { src: "/path/to/your/custom-font-bold.ttf", fontWeight: "bold" },
//     // ],
//   });

const Transcripts = props => {
    const model = '/auth/enrollment';
    const [enrollments, setEnrollments] = useState([]);

    useEffect(() => {
        loadEnrollments();
    }, []);

    const loadEnrollments = async () => {
        let cleanedData = [];
        const response = await processReq(GET, API_ROUTE + model);

        cleanedData = response.filter(cert => cert.certificateStatus > 0);

        for (let en of cleanedData) {
            en.qrCode = QRCode.toDataURL(`https://smart.clcsl.edu.lk/certificate?dist=${en.enrollmentId}`);

            const achivement = achivements.find(ach => ach.admission === en.legacyAdmNo);

            en.academicAchivements = achivement?.academicAchivements;
            en.otherAchivements = achivement?.otherAchivements;

            en.academicAchivements = en.academicAchivements?.split(',');
            en.otherAchivements = en.otherAchivements?.split(',');
        }

        setEnrollments(cleanedData);
    }

    return (
        <div className="container max-w-4xl mx-auto">
            {enrollments.length === 0 && "Loading..."}
            {enrollments.length > 0 &&
                <PDFViewer width="100%" height="600px" style={{ backgroundColor: '#eee' }}>
                    <Document>
                        {enrollments.map((enrollment, i) => (
                            <Page key={enrollment.id} size="A4" style={{ paddingVertical: 40, paddingHorizontal: 40, fontSize: 10, position: 'absolute' }}>
                                <View style={{ textAlign: 'center', width: '100%' }}>
                                    <Image src={logo} style={{ width: 90, height: 50, margin: 'auto' }} />
                                    <Text style={{ fontSize: 18 }}>Ceylon Ladies' Campus</Text>
                                    <Text style={{ fontSize: 14 }}>Academic Transcript</Text>
                                </View>

                                <View style={{ paddingVertical: 10, lineHeight: 1.2 }}>
                                    <Text style={{ textAlign: 'justify' }}>This transcript provides an official record of academic performance, reflecting the rigorous standards and comprehensive curriculum upheld by the institution. Each grade is a testament to the student’s dedication and academic achievement, demonstrating their proficiency in critical thinking, specialized knowledge, and practical skills relevant to their field of study. This document affirms the student’s readiness for future academic or professional endeavors.</Text>
                                </View>

                                <View style={{ borderTop: 1, borderBottom: 1, paddingTop: 4, paddingBottom: 4, marginTop: 6, marginBottom: 6 }}><Text>1. Information Identifying the Holder of the Qualification</Text></View>
                                <View style={{ paddingLeft: 10 }}>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'black', width: '45%' }}>1.1 Full Name</Text>
                                        <Text style={{ width: '55%' }}>: {enrollment.fullName.toUpperCase()}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>1.2 Admission Number</Text>
                                        <Text style={{ width: '55%' }}>: {enrollment.legacyAdmNo}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>1.3 Date of Birth (YYYY-MM-DD)</Text>
                                        <Text style={{ width: '55%' }}>: {enrollment.dob}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>1.4 Address</Text>
                                        <Text style={{ width: '55%' }}>: {enrollment.permAddress}</Text>
                                    </View>
                                </View>

                                <View style={{ borderTop: 1, borderBottom: 1, paddingTop: 4, paddingBottom: 4, marginTop: 6, marginBottom: 6 }}><Text>2. Information Identifying the Qualification</Text></View>
                                <View style={{ paddingLeft: 10 }}>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'black', width: '45%' }}>2.1 Course Name</Text>
                                        <Text style={{ width: '55%' }}>: {enrollment.courseName === "Commerce Stream 2024" ? enrollment.courseName + " with AAT" : enrollment.courseName}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>{"2.2 Language(s) of instruction and examination"}</Text>
                                        <Text style={{ width: '55%' }}>: {enrollment.courseName === "Commerce Stream 2024" ? "English" : "Tamil"}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>2.3 Name and status of awarding institution</Text>
                                        <Text style={{ width: '55%' }}>: Ceylon Ladies' Campus</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>2.4 Official Length of Programme</Text>
                                        <Text style={{ width: '55%' }}>: 2 years and 6 months</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>2.5 Certificate ID</Text>
                                        <Text style={{ width: '55%' }}>: {`CLC/${enrollment.courseName.slice(0, 3).toUpperCase()}/${enrollment.legacyAdmNo}`}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>2.6 Issued Date (YYYY-MM-DD)</Text>
                                        <Text style={{ width: '55%' }}>: {dayjs().format('YYYY-MM-DD')}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>2.7 Conduct</Text>
                                        <Text style={{ width: '55%' }}>: Very Good</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 2, paddingBottom: 2 }}>
                                        <Text style={{ fontWeight: 'bold', width: '45%' }}>2.8 Special aptitudes of pupil</Text>
                                        <Text style={{ width: '55%' }}>: Hard working diligent student</Text>
                                    </View>
                                    
                                </View>
                                {enrollment.academicAchivements?.length > 0 && (
                                    <View>
                                        <View style={{ borderTop: 1, borderBottom: 1, paddingTop: 4, paddingBottom: 4, marginTop: 6, marginBottom: 6 }}><Text>Academic Achievements</Text></View>
                                        <View style={{ paddingLeft: 10 }}>
                                            <View style={{ paddingTop: 2, paddingBottom: 2 }}>
                                                {enrollment.academicAchivements?.map((achievement, index) => {
                                                    return <View key={`3.${index + 1}`}><Text key={`3.${index + 1}`}>{`${index + 1}. ${achievement} `}</Text></View>
                                                })}
                                            </View>
                                        </View>
                                    </View>
                                )}
                                {enrollment.otherAchivements?.length > 0 && (
                                    <View>
                                        <View style={{ borderTop: 1, borderBottom: 1, paddingTop: 4, paddingBottom: 4, marginTop: 6, marginBottom: 6 }}><Text>Extra-Curricular Milestones</Text></View>
                                        <View style={{ paddingLeft: 10 }}>
                                            <View style={{ paddingTop: 2, paddingBottom: 2 }}>
                                                {enrollment.otherAchivements?.map((other, index) => {
                                                    return <View style={{paddingBottom: 2}} key={`4.${index + 1}`}><Text>{`${index + 1}. ${other} `}</Text></View>
                                                })}
                                            </View>
                                        </View>
                                    </View>
                                )}

                                    <View style={{ position: 'absolute', bottom: 190, right: 40, zIndex: 2}}>
                                        <Text style={{ fontWeight: 'bold', width: '45%', paddingLeft: 8 }}>{"E-Certificate"}</Text>
                                        <Image src={enrollment.qrCode} style={{ width: 70, height: 70 }} />
                                    </View>


                                <View style={{ position: 'absolute', bottom: 50, left: 0, right: 0 }}>
                                    <Image src={signatures} style={{ height: 100, margin: 'auto' }} />
                                </View>
                            </Page>
                        ))}
                    </Document>
                </PDFViewer>
            }
        </div>
    )
}

export default Transcripts;