export const getObjectById = (arr, id) => {
    return arr.find(ar => ar.id === id);
}

export const getLabel = (value, statusObject) => {
    if (statusObject) {
        let result;

        if (statusObject.formData) {
            result = statusObject.formData.find(dt => dt.id === value || dt.status === value);

            return result ? result.label : ''
        } else {
            result = statusObject.find(dt => dt.id === value || dt.status === value);

            return result ? result.label : ''
        }
    } else {

        return value;
    }

}


export const removePlaceholdersAndGetId = (value, placeholder) => {
    return value?.split(placeholder)[0];
}

export const removePlaceholdersAndGetValue = (value, placeholder) => {
    return value?.split(placeholder).length > 1 ? value?.split(placeholder)[1] : value?.split(placeholder)[0];
}
