export const placeholders = {
    dropdownDataPlaceholder: "-##-"
}

export const userRoles = {
    // 1Admin, 2Editor, 3Teacher
    admin: 1,
    editor: 2,
    teacher: 3,
    student: 4,
    formData: [
        { id: 1, label: 'Admin', value: 1 },
        { id: 2, label: 'Editor', value: 2 },
        { id: 3, label: 'Teacher', value: 3 },
        { id: 4, label: 'Student', value: 4 }
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case userRoles.admin:
                return userRoles.formData[0].label;
            case userRoles.editor:
                return userRoles.formData[1].label;
            case userRoles.teacher:
                return userRoles.formData[2].label;
            case userRoles.student:
                return userRoles.formData[3].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const userStatus = {
    active: 1,
    suspended: 2
}

export const courseStatus = {
    toBeStarted: 1,
    active: 2,
    hold: 3,
    completed: 4,
    formData: [
        { id: 1, label: 'To Be Started', value: 1 },
        { id: 2, label: 'Active', value: 2 },
        { id: 3, label: 'Hold', value: 3 },
        { id: 4, label: 'Completed', value: 4 }
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case courseStatus.toBeStarted:
                return courseStatus.formData[0].label;
            case courseStatus.active:
                return courseStatus.formData[1].label;
            case courseStatus.hold:
                return courseStatus.formData[2].label;
            case courseStatus.completed:
                return courseStatus.formData[3].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const gender = {
    male: 1,
    female: 2,
    formData: [
        { id: 1, label: 'Male', value: 1 },
        { id: 2, label: 'Female', value: 2 },
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case gender.male:
                return gender.formData[0].label;
            case gender.female:
                return gender.formData[1].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const sourceOfInfo = {
    formData: [
        { id: 1, label: "Facebook", value: "Facebook" },
        { id: 2, label: "Website", value: "Website" },
        { id: 3, label: "Whatsapp", value: "Whatsapp" },
        { id: 4, label: "Radio Advertisement", value: "Radio Advertisement" },
        { id: 5, label: "Instagram Advertisment", value: "Instagram Advertisment" },
        { id: 6, label: "From a friend", value: "From a friend" },
        { id: 7, label: "From a staff at CLC", value: "From a staff at CLC" },
    ]
}

export const examType = {
    proper: "Proper",
    repeat: "Repeat",
    formData: [
        { id: 1, label: "Proper", value: "Proper" },
        { id: 2, label: "Repeat", value: "Repeat" },
    ]
}

export const nationality = {
    formData: [
        { id: 1, label: "Sri Lankan Moor", value: "Sri Lankan Moor" },
        { id: 2, label: "Sri Lankan Budhist", value: "Sri Lankan Budhist" },
    ]
}

export const district = {
    formData: [
        {id: 1, label: "Colombo", value: "Colombo"},
        {id: 2, label: "Gampaha", value: "Gampaha"},
        {id: 3, label: "Kalutara", value: "Kalutara"},
        {id: 4, label: "Kandy", value: "Kandy"},
        {id: 5, label: "Matale", value: "Matale"},
        {id: 6, label: "Nuwara Eliya", value: "Nuwara Eliya"},
        {id: 7, label: "Galle", value: "Galle"},
        {id: 8, label: "Matara", value: "Matara"},
        {id: 9, label: "Hambantota", value: "Hambantota"},
        {id: 10, label: " Jaffna", value: " Jaffna"},
        {id: 11, label: " Kilinochchi[1]", value: " Kilinochchi[1]"},
        {id: 12, label: " Mannar", value: " Mannar"},
        {id: 13, label: " Vavuniya", value: " Vavuniya"},
        {id: 14, label: " Mullaitivu", value: " Mullaitivu"},
        {id: 15, label: " Batticaloa", value: " Batticaloa"},
        {id: 16, label: " Ampara", value: " Ampara"},
        {id: 17, label: " Trincomalee", value: " Trincomalee"},
        {id: 18, label: " Kurunegala", value: " Kurunegala"},
        {id: 19, label: " Puttalam", value: " Puttalam"},
        {id: 20, label: " Anuradhapura", value: " Anuradhapura"},
        {id: 21, label: " Polonnaruwa", value: " Polonnaruwa"},
        {id: 22, label: " Badulla", value: " Badulla"},
        {id: 23, label: " Moneragala", value: " Moneragala"},
        {id: 24, label: " Ratnapura", value: " Ratnapura"},
        {id: 25, label: " Kegalle", value: " Kegalle"},
    ]
}

export const mobileNumberRegions = {
    formData: [
        { id: 1, label: "+94", value: "94" }
    ]
}

export const commonBoolean = {
    required: true,
    // required: false
}

export const messages = {
    requiredMessage: 'This field cannot be empty. Enter valid data!',
    invalidMobile: 'Invalid Mobile Number Format'
}

export const enrollmentStatus = {
    pending: 1,
    approved: 2,
    active: 3,
    hold: 4,
    completed: 5,
    formData: [
        { id: 1, label: 'Pending', value: 1 },
        { id: 2, label: 'Approved', value: 2 },
        { id: 3, label: 'Active', value: 3 },
        { id: 4, label: 'Hold', value: 4 },
        { id: 5, label: 'Completed', value: 5 }
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case enrollmentStatus.pending:
                return enrollmentStatus.formData[0].label;
            case enrollmentStatus.approved:
                return enrollmentStatus.formData[1].label;
            case enrollmentStatus.active:
                return enrollmentStatus.formData[2].label;
            case enrollmentStatus.hold:
                return enrollmentStatus.formData[3].label;
            case enrollmentStatus.completed:
                return enrollmentStatus.formData[4].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const schoolingType = {
    hosteller: 1,
    dayScholar: 2,
    formData: [
        { id: 1, label: 'Hosteller', value: 1 + placeholders.dropdownDataPlaceholder + 'Hosteller' },
        { id: 2, label: 'Day Schollar', value: 2 + placeholders.dropdownDataPlaceholder + 'Day Schollar' },
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case schoolingType.hosteller:
                return schoolingType.formData[0].label;
            case schoolingType.dayScholar:
                return schoolingType.formData[1].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const taskStatus = {
    toDo: 1,
    inProgess: 2,
    submitted: 3,
    reAssigned: 4,
    closed: 5,
    formData: [
        { id: 1, label: 'To Do', value: 1 },
        { id: 2, label: 'In Progress', value: 2 },
        { id: 3, label: 'Submitted', value: 3 },
        { id: 4, label: 'Re Assigned', value: 4 },
        { id: 5, label: 'Closed', value: 5 },
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case taskStatus.toDo:
                return taskStatus.formData[0].label;
            case taskStatus.inProgess:
                return taskStatus.formData[1].label;
            case taskStatus.submitted:
                return taskStatus.formData[2].label;
            case taskStatus.reAssigned:
                return taskStatus.formData[3].label;
            case taskStatus.closed:
                return taskStatus.formData[4].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const paymentStatus = {
    paid: 1,
    toBePaid: 2,
    waived: 3,
    partPayment: 4,
    formData: [
        { id: 1, label: 'Paid', value: 1 },
        { id: 2, label: 'To Be Paid', value: 2 },
        { id: 3, label: 'Waived', value: 3 },
        { id: 4, label: 'Part Payment', value: 4 },
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case paymentStatus.paid:
                return paymentStatus.formData[0].label;
            case paymentStatus.toBePaid:
                return paymentStatus.formData[1].label;
            case paymentStatus.waived:
                return paymentStatus.formData[2].label;
            case paymentStatus.partPayment:
                return paymentStatus.formData[3].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const paymentType = {
    admissionFee: 1,
    monthlyFee: 2,
    formData: [
        { id: 1, label: 'Admission Fee', value: 1 },
        { id: 2, label: 'Monthly Fee', value: 2 },
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case paymentType.admissionFee:
                return paymentType.formData[0].label;
            case paymentType.monthlyFee:
                return paymentType.formData[1].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const attendanceType = {
    present: "PRESENT",
    absent: "ABSENT",
}

export const amendStatus = {
    readonly: 1,
    edittable: 2,
    formData: [
        { id: 1, label: 'Read Only', value: 1 },
        { id: 2, label: 'Edittable', value: 2 },
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case amendStatus.admissionFee:
                return amendStatus.formData[0].label;
            case amendStatus.monthlyFee:
                return amendStatus.formData[1].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const paymentMethod = {
    cash: 1,
    card: 2,
    bankDeposit: 3,
    onlineBankTransfer: 4,
    formData: [
        { id: 1, label: 'Cash', value: 1 },
        { id: 2, label: 'Card', value: 2 },
        { id: 3, label: 'Bank Deposit', value: 3 },
        { id: 4, label: 'Online Bank Transfer', value: 4 },
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case paymentMethod.cash:
                return paymentMethod.formData[0].label;
            case paymentMethod.card:
                return paymentMethod.formData[1].label;
            case paymentMethod.bankDeposit:
                return paymentMethod.formData[2].label;
            case paymentMethod.onlineBankTransfer:
                return paymentMethod.formData[3].label;
            default:
                return 'Invalid Status Maping';
        }
    }
}

export const commonExams = {
    gceOl: "GCE O/L",
    subjectTypeOl: "OL",
    subjectTypeAl: "AL"
}


export const certificateStatus = {
    inactive: 1,
    requested: 2,
    underReview: 3,
    approved: 4,
    rejected: 5,
    issued: 6,
    formData: [
        { id: 1, label: 'Inactive', value: 1 },
        { id: 2, label: 'Requested', value: 2 },
        { id: 3, label: 'Under Review', value: 3 },
        { id: 4, label: 'Approved', value: 4 },
        { id: 5, label: 'Rejected', value: 5 },
        { id: 6, label: 'Issued', value: 6 }
    ],
    getLabel: (id) => {
        switch (parseInt(id)) {
            case certificateStatus.inactive:
                return certificateStatus.formData[0].label;
            case certificateStatus.requested:
                return certificateStatus.formData[1].label;
            case certificateStatus.underReview:
                return certificateStatus.formData[2].label;
            case certificateStatus.approved:
                return certificateStatus.formData[3].label;
            case certificateStatus.rejected:
                return certificateStatus.formData[4].label;
            case certificateStatus.issued:
                return certificateStatus.formData[5].label;
            default:
                return 'Invalid Status Mapping';
        }
    }
}
