import { App, Button, DatePicker, Modal, Popconfirm, Select, TimePicker, message, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { subjectsData, timetables } from '../../utils/dummyData';
import { ClearOutlined, CloseCircleFilled, DownCircleFilled, DownloadOutlined, EditFilled, EyeFilled, InfoCircleFilled, PlusCircleFilled, PrinterFilled, RestOutlined, SaveFilled } from '@ant-design/icons';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { fetcher, processReq } from '../../helpers/processRequest';
import { DELETE, GET, POST, PUT } from '../../utils/requestActionConstants';
import { API_ROUTE } from '../../utils/commonConstants';
import useSWR from 'swr';
import { usePDF } from 'react-to-pdf';

const { RangePicker } = DatePicker;

const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const TimeTableDaySlot = ({ day, dayIndex, slot, slotIndex, setTimeTable, timeTable, subjects, existingRecords }) => {
    const lecturerResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/lecturer' }, fetcher);
    const [currentSubject, setCurrentSubject] = useState(undefined);
    const [currentTeacher, setCurrentTeacher] = useState(slot ? slot.lecturer : undefined);
    const [currentTimePickerRange, setCurrentTimePickerRange] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [isShowInfo, setIsShowInfo] = useState(false);
    // const [api, contextHolder] = notification.useNotification();
    const { message, notification } = App.useApp();

    useEffect(() => {
        loadCurrentDateRange();
        loadInitialData();
    }, []);

    const loadInitialData = () => {
        setCurrentSubject(slot ? slot.subject : undefined);
        setCurrentTeacher(slot ? slot.lecturer : undefined);
    }

    const loadCurrentDateRange = async () => {
        if (slot.from || slot.to) {
            setCurrentTimePickerRange([dayjs(slot.from), dayjs(slot.to)]);
        } else {
            switch (slotIndex) {
                case 0:
                    await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { from: dayjs().set('hour', 5).set('minute', 30), to: dayjs().set('hour', 7).set('minute', 30), id: slot.id });
                    setCurrentTimePickerRange([dayjs().set('hour', 5).set('minute', 30), dayjs().set('hour', 7).set('minute', 30)]);
                    break;
                case 1:
                    await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { from: dayjs().set('hour', 7).set('minute', 30), to: dayjs().set('hour', 8).set('minute', 0), id: slot.id });
                    setCurrentTimePickerRange([dayjs().set('hour', 7).set('minute', 30), dayjs().set('hour', 8).set('minute', 0)])
                    break;
                case 2:
                    await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { from: dayjs().set('hour', 8).set('minute', 0), to: dayjs().set('hour', 10).set('minute', 0), id: slot.id });
                    setCurrentTimePickerRange([dayjs().set('hour', 8).set('minute', 0), dayjs().set('hour', 10).set('minute', 0)])
                    break;
                case 3:
                    await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { from: dayjs().set('hour', 10).set('minute', 15), to: dayjs().set('hour', 12).set('minute', 15), id: slot.id });
                    setCurrentTimePickerRange([dayjs().set('hour', 10).set('minute', 15), dayjs().set('hour', 12).set('minute', 15)])
                    break;
                case 4:
                    await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { from: dayjs().set('hour', 12).set('minute', 45), to: dayjs().set('hour', 14).set('minute', 0), id: slot.id });
                    setCurrentTimePickerRange([dayjs().set('hour', 12).set('minute', 45), dayjs().set('hour', 14).set('minute', 0)])
                    break;
                case 5:
                    await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { from: dayjs().set('hour', 15).set('minute', 0), to: dayjs().set('hour', 17).set('minute', 0), id: slot.id });
                    setCurrentTimePickerRange([dayjs().set('hour', 15).set('minute', 0), dayjs().set('hour', 17).set('minute', 0)])
                    break;
                default:
                    return 'none'
            }
        }

    }

    const handleTimeChange = async (event, index) => {
        setCurrentTimePickerRange(event);
        await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { from: dayjs(event[0]), to: dayjs(event[1]), id: slot.id });

        validate();
    }

    const onChangeSubject = async (_, subject) => {
        await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { subjectId: subject.id, id: slot.id });
        setIsSaved(false);
        setIsShowInfo(false);
        setCurrentTeacher(undefined);
        setCurrentSubject(subject);
        validate();
        message.success('Updated!');
    }

    const onChangeTeacher = async (_, teacher) => {
        await processReq(PUT, API_ROUTE + "/auth/timetableDaySlot", { lecturerId: teacher.id, id: slot.id });
        setIsSaved(false);
        setIsShowInfo(false);
        setCurrentTeacher(teacher);
        validate();
        message.success('Updated!');
    }

    const removeTimeTableDaySlot = async (day, dayIndex, slot, slotIndex) => {
        let timeTableArray = timeTable;

        await processReq(DELETE, API_ROUTE + "/auth/timetableDaySlot" + '/del/' + slot.id, slot);
        delete timeTableArray[dayIndex].timeTableDaySlots[slotIndex]; // Delete one element in by using slotIndex

        setTimeTable([...timeTableArray]);

    }

    const saveTimeTableDaySlot = (day, dayIndex, currrentSlot, slotIndex) => {
        if (validate()) {
            let timeTableArray = timeTable;
            let slot = timeTableArray[dayIndex].timeTableDaySlots[slotIndex];

            slot.from = currentTimePickerRange[0];
            slot.to = currentTimePickerRange[1];
            slot.subject = currentSubject;
            slot.lecturer = currentTeacher;

            // console.log('printing current slot details', slot);

            setTimeTable([...timeTableArray]);
            setIsSaved(true);
        } {
            // message.warning("Validation Failed!")
        }
    }

    const validate = () => {
        const currentDays = timeTable;
        const existingDays = existingRecords;

        let isValid = true;

        if (!currentSubject || !currentTeacher) {
            // message.warning('Please select a teacher and a subject!');

            return false;
        }

        for (let timetable of existingDays) {
            if (timetable.timeTableDays) {
                for (let oldDay of timetable.timeTableDays) {
                    let oldCheckDay = dayjs(oldDay.date).format('YYYY-MM-DD');
                    let currentCheckDay = dayjs(day.date).format('YYYY-MM-DD');

                    if (currentCheckDay === oldCheckDay) { // Check current day
                        for (let timeTableDaySlot of oldDay.timeTableDaySlots) {
                            if (timeTableDaySlot) {
                                let currentSlotTime = currentTimePickerRange;
                                let oldSlotTime = {
                                    from: dayjs().set('hour', dayjs(timeTableDaySlot.from).format('HH')).set('minute', dayjs(timeTableDaySlot.from).format('mm')),
                                    to: dayjs().set('hour', dayjs(timeTableDaySlot.to).format('HH')).set('minute', dayjs(timeTableDaySlot.to).format('mm')),
                                };

                                if (
                                    oldSlotTime.from.isSame(currentSlotTime[0], 'minute') && oldSlotTime.to.isSame(currentSlotTime[1], 'minute') ||
                                    oldSlotTime.from.isBefore(currentSlotTime[1], 'minute') && oldSlotTime.to.isAfter(currentSlotTime[0], 'minute')
                                ) {
                                    let foundSlotTeacher = timeTableDaySlot.lecturer;
                                    let foundSlotSubject = timeTableDaySlot.subject;

                                    if (foundSlotTeacher.id === currentTeacher.id) {
                                        setIsShowInfo(true);
                                        notification.warning({
                                            message: 'Warning!',
                                            description: 'Teacher going to be assigned 2 times.',
                                            placement: 'top'
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        isValid = false;
        return isValid;
    }

    return (
        <div className={`${isShowInfo ? 'bg-red-400 text-white' : 'bg-blue-50'} p-1 pop-left fade md:p-2 shadow-lg rounded-xl bg-white/60`}>
            {/* {contextHolder} */}
            <div className='flex justify-between px-3 py-1 mb-1 border-s-violet-100'>
                <Popconfirm
                    title="Delete the this item"
                    description="Are you sure to delete this record?"
                    onConfirm={() => removeTimeTableDaySlot(day, dayIndex, slot, slotIndex)}
                    okButtonProps={{ danger: true }}
                    okText="Yes"
                    cancelText="No"
                    className={isShowInfo ? 'text-white' : 'text-red-500'}
                >
                    <CloseCircleFilled />
                </Popconfirm>
                {isShowInfo ? <InfoCircleFilled className={'animate-pulse text-red-800'} onClick={() => saveTimeTableDaySlot(day, dayIndex, slot, slotIndex)} /> : ''}
            </div>

            <TimePicker.RangePicker value={currentTimePickerRange} variant={isShowInfo ? 'outlined' : 'borderless'} use12Hours changeOnBlur onCalendarChange={(event) => handleTimeChange(event, dayIndex)} format={'HH:mm'} className={'bg-blue-50 w-full'} />
            <Select
                showSearch
                placeholder="Select a subject"
                optionFilterProp="children"
                onChange={onChangeSubject}
                filterOption={filterOption}
                options={subjects}
                variant={isShowInfo ? 'outlined' : 'borderless'}
                value={currentSubject}
                className={'w-full flex-wrap bg-blue-50 rounded-lg'}
            />
            {
                currentSubject ?
                    <Select
                        showSearch
                        placeholder="Select a lecturer"
                        optionFilterProp="children"
                        onChange={onChangeTeacher}
                        filterOption={filterOption}
                        options={currentSubject?.lecturers}
                        value={currentTeacher}
                        className={'w-full flex-wrap bg-blue-50 rounded-lg'}
                        variant={isShowInfo ? 'outlined' : 'borderless'}
                    />
                    : ''
            }
        </div>
    )
}

const TimeTable = () => {
    const timetableResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/timetable' }, fetcher, {refreshInterval: 3000});
    const subjectResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/subject' }, fetcher);
    const coursesResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/course' }, fetcher);
    const [timetableDateRange, setTimetableDateRange] = useState([]);
    const [datePickerDisabled, setDatePicketDisabled] = useState(false);
    const [timeTableLoaded, setTimeTableLoaded] = useState(false);
    const [timeTable, setTimeTable] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(undefined);
    const [existingTimeTablesForCurrentDateRange, setExistingTimeTablesForCurrentDateRange] = useState(timetables); // this should be loaded from a database
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [timetableViewContent, setTimetableViewContent] = useState([]);
    const [currentRecord, setCurrentRecord] = useState({});
    const { toPDF, targetRef } = usePDF({ filename: 'clc_data.pdf', page: {orientation: 'landscape', size: 'A5'} });

    // const pdfRef = useRef(null);


    const handleDateChange = (value) => {
        setTimetableDateRange(value);
    }

    const loadTimeTable = async () => {
        if (timetableDateRange.length !== 2 || !selectedCourse) {
            message.warning('Course and a Time Range is mandatory!');
        } else {
            let fromDate = timetableDateRange[0];
            let toDate = timetableDateRange[1];
            const existingTimetable = await processReq(POST, API_ROUTE + "/auth/timetable/betweenDates", { startDate: fromDate, courseId: selectedCourse.id });

            if (existingTimetable.length > 0) {
                const timeTableArray = existingTimetable[0];

                setTimeTable(timeTableArray.timeTableDays);
                setDatePicketDisabled(true);
                setTimeTableLoaded(true);
            } else {
                let numberOfDays = toDate.diff(fromDate, 'day');
                let timeTableArray = [];
                const reqObj = { startDate: fromDate, endDate: toDate, description: selectedCourse.courseName, courseId: selectedCourse.id };
                const addedTable = await processReq(POST, API_ROUTE + "/auth/timetable", reqObj);

                for (let i = 0; i <= numberOfDays; i++) {
                    await processReq(POST, API_ROUTE + "/auth/timeTableDay", {
                        timeTableId: addedTable.id,
                        date: fromDate.add(i, 'day'),
                        title: fromDate.add(i, 'day').format('YYYY-MM-DD dddd'),
                    });
                }

                const addedTimetable = await processReq(POST, API_ROUTE + "/auth/timetable/dist/" + addedTable.id);

                setTimeTable(addedTimetable.timeTableDays);
                setDatePicketDisabled(true);
                setTimeTableLoaded(true);
            }
            timetableResponse.mutate();
        }
    }

    const resetTimeTable = () => {
        setDatePicketDisabled(false);
        setTimeTableLoaded(false);
        setTimetableDateRange([])
        setSelectedCourse(undefined);
        setCurrentRecord({});
    }

    const addTimeTableDaySlot = async (index) => {
        let timeTableArray = timeTable;

        const response = await processReq(POST, API_ROUTE + "/auth/timeTableDaySlot", {
            timeTableDayId: timeTableArray[index].id,
            // from: ,
            // to:
        });
        timeTableArray[index].timeTableDaySlots.push(response);

        timetableResponse.mutate();

        setTimeTable([...timeTableArray]);
    }

    const onChangeCourse = (_, object) => {
        setSelectedCourse(object)
    }

    const saveTimeTable = (view) => {
        let finalTimeTable = {
            id: 1,
            startDate: timetableDateRange[0],
            endDate: timetableDateRange[1],
            description: selectedCourse?.label,
            timeTableDays: timeTable,
            course: selectedCourse
        }

        // console.log('viewing', timeTable)

        if (view) {
            viewTimetable(finalTimeTable);
        } else {
            // Save timetable
            // console.log(finalTimeTable)
        }
    }

    const loadExistingTimetable = (item) => {
        setTimeTable([]);
        setTimeTable(item.timeTableDays);
        setCurrentRecord(item);
        setTimeTableLoaded(true);

        // console.log(item);
    }

    const viewTimetable = (item) => {
        setViewModalOpen(true);
        setTimetableViewContent(item);
        // console.log(item);
    }

    const deleteTimeTable = async (item) => {
        const response = await processReq(DELETE, API_ROUTE + "/auth/timetable" + '/del/' + item.id, item);
        timetableResponse.mutate();
        message.success("Deleted!")
    }

    const downloadPDF = (onlyPrint) => {
        // const pdf = new jsPDF({ orientation: 'landscape', format: 'a5' });

        // pdf.html(pdfRef.current, {
        //     callback: (doc => {
        //         if (onlyPrint) {
        //             doc.autoPrint();
        //             doc.output('dataurlnewwindow');
        //         } else {
        //             doc.save('test.pdf');
        //         }
        //     }), html2canvas: { scale: 0.25 }
        // });
        toPDF()
    }

    return (
        <div>
            <Modal
                title={timetableViewContent.description}
                centered
                open={viewModalOpen}
                onCancel={() => setViewModalOpen(false)}
                okButtonProps={{ className: 'bg-blue-800' }}
                footer={null}
                width={window.innerWidth}
            >
                <div ref={targetRef} className='text-center'>
                    <div className='text-xl'>{timetableViewContent.description}</div>
                    <div className='text-lg'>{dayjs(timetableViewContent.startDate).format('Do MMM YYYY (dddd)')} - {dayjs(timetableViewContent.endDate).format('Do MMM YYYY (dddd)')}</div>
                    <div className='p-3 flex justify-center'>
                        {
                            timetableViewContent.timeTableDays ? timetableViewContent.timeTableDays.map((content, id) => {
                                return (
                                    <div key={content.id + content.title + id} className='border border-slate-100'>
                                        <div className='p-2 border-b text-xs text-center font-bold'>{dayjs(content.date).format('YYYY-MM-DD')} <br /> {dayjs(content.date).format('dddd')}</div>
                                        {
                                            content.timeTableDaySlots.map((slot, slotIndex) => {
                                                if (slot && slot.subject && slot.lecturer) {
                                                    return (
                                                        <div className='p-2 border-b text-xs' key={content.id + "_" + slotIndex}>
                                                            {dayjs(slot.from).format('HH:mm')} - {dayjs(slot.to).format('HH:mm')} <br />
                                                            {slot.subject.label} <br /> {slot.lecturer.label}
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                )
                            })
                                : ''
                        }
                    </div>
                </div>

                <div className='text-right'>
                    <Button type='link' icon={<DownloadOutlined style={{ fontSize: 20 }} />} className='' onClick={downloadPDF}>Download</Button>
                    {/* <Button type='link' icon={<PrinterFilled style={{ fontSize: 20 }} />} className='' onClick={() => downloadPDF(true)}>Print</Button> */}
                </div>
            </Modal>
            <div className="text-2xl">Time Table Management</div>
            <div className="pt-5">
                <div className='pr-2'>Existing Timetables From Database : </div>
                <div className='flex overflow-scroll' style={{ maxWidth: '100vw' }} >
                    {timetableResponse.isLoading ? "Loading..." : timetableResponse.data?.map((item, index) => {
                        return (
                            <div style={{ minWidth: 350 }} key={(item.id + index).toString()} className='px-3 py-1 border mr-1 rounded-md gradient-bg-3 text-xs'>
                                {item.course?.courseName} ({item.startDate} - {item.endDate}) <span className='pl-5 cursor-pointer' onClick={() => viewTimetable(item)}><EyeFilled /></span> <span className='pl-1 cursor-pointer' onClick={() => loadExistingTimetable(item)}><EditFilled /></span>
                                <Popconfirm
                                    title="Delete the this item"
                                    description="Are you sure to delete this record?"
                                    onConfirm={() => deleteTimeTable(item)}
                                    okButtonProps={{ danger: true }}
                                    okText="Yes"
                                    cancelText="No"
                                    className='pl-2'
                                >
                                    <CloseCircleFilled />
                                </Popconfirm>
                            </div>
                        )
                    })}
                </div>
                {/* {
                    timeTableLoaded ?
                        <div className='pb-2 flex overflow-x-auto text-xs items-center'>
                            <div className='pr-2'>Existing Timetables : </div>
                            {existingTimeTablesForCurrentDateRange.map((item, index) => {
                                return (
                                    <div key={(item.id + index).toString()} className='px-3 py-1 w-fit border mr-1 rounded-md gradient-bg-3 text-xs'>{item.description} <span className='pl-5 cursor-pointer' onClick={() => viewTimetable(item)}><EyeFilled /></span> <span className='pl-1 cursor-pointer' onClick={() => loadExistingTimetable(item)}><EditFilled /></span></div>
                                )
                            })}
                        </div>
                        : ''

                } */}

                <div className='mt-2 p-2 shadow-xl rounded-lg gradient-bg-4'>
                    <Select
                        showSearch
                        placeholder="Select a course"
                        optionFilterProp="children"
                        onChange={onChangeCourse}
                        filterOption={filterOption}
                        options={coursesResponse.data}
                        className={'md:mr-5'}
                        disabled={datePickerDisabled}
                        value={selectedCourse}
                    />

                    <RangePicker
                        onChange={handleDateChange}
                        changeOnBlur
                        value={timetableDateRange}
                        disabled={datePickerDisabled}
                        style={{ width: 230 }}
                    />

                    <Button shape='circle' type='link' icon={<PlusCircleFilled />} className='md:ml-5' disabled={datePickerDisabled} onClick={loadTimeTable}></Button>

                    <Button shape='circle' type='link' icon={<SaveFilled />} disabled={!datePickerDisabled} onClick={saveTimeTable}></Button>

                    <Button shape='circle' type='link' icon={<EyeFilled />} disabled={!timeTableLoaded} onClick={() => saveTimeTable(true)}></Button>

                    <Button shape='circle' type='link' danger icon={<ClearOutlined />} disabled={!timeTableLoaded} onClick={resetTimeTable}></Button>

                </div>

                {currentRecord.id ?
                <div className='pt-5'>
                    <div className='text-lg'>{currentRecord.description}</div>
                    <div className='text-lg'>From {dayjs(currentRecord.startDate).format('YYYY MMM DD')} To {dayjs(currentRecord.endDate).format('YYYY MMM DD')}</div>
                </div>
                : <></>
                }
                {
                    timeTableLoaded ?
                        <div className='mt-2 p-2 shadow-xl rounded-lg gradient-bg-4 overflow-x-auto'>
                            {
                                timeTable?.map((day, dayIndex) => {
                                    return (
                                        <div className='' key={(day.id + dayIndex.toString())}>
                                            <div className='flex content-center justify-start'>
                                                <div className='p-4 text-center text-xs' style={{ maxWidth: 200, minWidth: 200 }}>
                                                    <div>{day.title}</div>
                                                    <Button onClick={() => addTimeTableDaySlot(dayIndex)}>+</Button>
                                                </div>
                                                {
                                                    day.timeTableDaySlots.map((slot, slotIndex) => {
                                                        if (slot) {
                                                            return (
                                                                <div key={day.id + "-" + slotIndex} className='rounded border-slate-200 py-2 pr-2' style={{ maxWidth: 165 }}>
                                                                    <TimeTableDaySlot
                                                                        day={day}
                                                                        dayIndex={dayIndex}
                                                                        slot={slot}
                                                                        slotIndex={slotIndex}
                                                                        timeTable={timeTable}
                                                                        setTimeTable={setTimeTable}
                                                                        subjects={subjectResponse.data}
                                                                        existingRecords={existingTimeTablesForCurrentDateRange}
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : ''
                }
            </div>
        </div>
    );
}


export default TimeTable;
