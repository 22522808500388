import { App, Button, DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import { fetcher, processReq } from "../../helpers/processRequest";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";
import DataTable from "../../components/DataTable";
import { commonBoolean, messages, taskStatus } from "../../utils/configConstants";
import Marquee from "react-fast-marquee";

const Tasks = () => {
    const model = '/auth/task';
    const usersResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/user' }, fetcher);
    const result = useSWR({ method: GET, url: API_ROUTE + model }, fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [form] = Form.useForm();
    const { message } = App.useApp();

    useEffect(() => {
        resetForm();
    }, [])

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + model + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }

        result.mutate();
    }

    const editRecord = (record) => {
        let object = {
            ...record,
        }

        setSelectedRecord(object);
        form.setFieldsValue(object);
        setIsModalOpen(true);
    }

    const addRecord = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedRecord.id) {
            // console.log({...data, id: selectedRecord.id})
            await processReq(PUT, API_ROUTE + model, { ...data, id: selectedRecord.id });
            result.mutate();
            message.success('Updated!')
        } else {
            await processReq(POST, API_ROUTE + model, data);
            result.mutate();
            message.success('Saved!')
        }

        handleCancel();
    }

    const resetForm = () => {
        setSelectedRecord({})
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            <div className="text-2xl">Tasks Management</div>
            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Record</Button></div>
            <div>
                <DataTable
                    columns={[
                        "id",
                        "title",
                        "statusLabel",
                        "userName",
                        "email"
                    ]}
                    data={result.data}
                    isLoading={result.isLoading}
                    deleteAction={deleteRecord}
                    editAction={editRecord}
                />
            </div>

            <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                    <Form.Item label='Title' name={'title'} rules={[{ required: true, message: messages.requiredMessage }]}>
                        <Input placeholder="Enter text" />
                    </Form.Item>

                    <Form.Item label='Description' name={'description'} rules={[{ required: true, message: messages.requiredMessage }]}>
                        <Input placeholder="Enter text" />
                    </Form.Item>

                    {!selectedRecord.id ?
                        <>
                            <Form.Item label='Start Date' name={'startDate'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                                <DatePicker placeholder="Start Date" />
                            </Form.Item>

                            <Form.Item label='Due Date' name={'dueDate'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                                <DatePicker placeholder="Due Date" />
                            </Form.Item>
                        </> : <></>
                    }

                    <Form.Item label='Task Status' name={'taskStatus'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                        <Select
                            showSearch
                            placeholder="Select status"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={taskStatus.formData}
                        />
                    </Form.Item>

                    <Form.Item label='User' name={'userId'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                        <Select
                            showSearch
                            placeholder="Select a user"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={usersResponse.data}
                        />
                    </Form.Item>

                    <Form.Item className="text-right">
                        <Button htmlType="submit">Save Record</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Tasks;