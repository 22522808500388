import React, {useContext, useEffect, useState} from 'react'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Sidebar, {navs} from '../components/SideBar';
import {Avatar, Button, Switch} from 'antd';
import Login from '../components/Login';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../context/AuthContextProvider';
import {routes} from "../App";


function Home() {
    const {logOut, isLoggedIn, user, isAuthLoading} = useContext(AuthContext);
    const [hidden, setHidden] = useState(false);
    const [sideBarStyles, setSideBarStyles] = useState({maxWidth: 200});
    const navigate = useNavigate();
    const location =  useLocation();
    const allowedRoutes = routes.filter(route => route.allowedFor.some(role => role === parseInt(user?.userRole)));
    const currentRoute = allowedRoutes.find(rt => rt.path === location.pathname);

    useEffect(() => {
        let styles = {transition: '0.4s', overflow: 'hidden'};

        if (hidden) {
            styles.maxWidth = 10;
            styles.minWidth = 10;
        } else {
            styles.maxWidth = 200;
            styles.minWidth = 200;
        }

        setSideBarStyles(styles);
    }, [hidden]);

    if (isAuthLoading) {
        return <div>Loading.... Please Wait</div>
    } else if (isLoggedIn) {
        return (
            <div className={''}>
                <div className='flex gradient-bg-2'>
                    <div className='gradient-bg-2 h-screen absolute z-40 md:relative pt-10 md:pt-0'
                         style={sideBarStyles} onMouseEnter={() => setHidden(false)}><Sidebar hidden={hidden} setHidden={setHidden}/>
                    </div>
                    <div className='grow overflow-y-auto h-screen'>
                        <div className='sticky top-0 gradient-bg-1 pr-5 py-2 z-40 flex'>
                            <div><Button type='link' onClick={() => setHidden((old) => !old)}>{hidden ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Button></div>

                            <div className='grow text-right px-2 text-sm cursor-pointer' onClick={() => navigate('/profile')}><Avatar className='mr-2' icon={<UserOutlined />}/>{user.userName} <Button type='link' onClick={logOut}><PoweroffOutlined /></Button></div>
                        </div>
                        <div className='p-10'>
                            {currentRoute?.path ? <Outlet /> : <div>Sorry! you don't have access to this route</div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <Login/>
    }


}

export default Home
