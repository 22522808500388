import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";
import LecturerSubjects from "./tabItems/LecturerSubjects";
import LecturerDetails from "./tabItems/LecturerDetails";
import UserProfileView from "../../components/UserProfileView";
import LecturerTimetable from "./tabItems/LecturerTimeTable";
import LecturerPayments from "./tabItems/LecturerPayments";

const LecturerTab = () => {
    const location = useLocation();
    const lecturer = location.state;
    lecturer.lecturerId = lecturer.id;
    const navigate = useNavigate();

    const tabs = [
        {
          key: '2',
          label: 'Lecturer Details',
          children: <LecturerDetails formData={lecturer} />,
        },
        {
            key: '3',
            label: 'Subjects',
            children: <LecturerSubjects formData={lecturer} />,
          },
        // {
        //     key: '4',
        //     label: 'Payments',
        //     children: <LecturerPayments formData={lecturer} />,
        //   },
          {
            key: '5',
            label: 'User Account',
            children: <UserProfileView formData={lecturer} />,
          },
          {
            key: '6',
            label: 'Time Table',
            children: <LecturerTimetable formData={lecturer} />,
          },
    ];

    const onChange = (key) => {
        // console.log(key);
    };

    return (
        <div>
            <div className="">
                <div onClick={() => navigate('/lecturers')} className="p-3 rounded-lg bg-cyan-50 w-10 h-10 mb-2 cursor-pointer hover:scale-110 shadow duration-200 ease-in-out"><div className="icon-arrow-left"></div></div>
                <Tabs defaultActiveKey="1" items={tabs} onChange={onChange} tabBarStyle={{fontWeight: 'bold', backgroundColor: '#e4f6ff', borderRadius: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10, boxShadow: 'rgb(218 229 235) 0px 3px 9px 0px'}} />
            </div>
        </div>
    )
}

export default LecturerTab;