import './App.css';
import Root from "./pages/Root";
import Dashboard from './pages/dashboard/Dashboard';
import Blog from './pages/blog/Blog';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.scss';
import { Provider } from 'react-redux';
import store from './store';
import FileManager from './pages/filemanager/FileManager';
import SMS from './pages/sms/SMS';
import Dormitory from './pages/dormitory/Dormitory';
import NoticeBoard from './pages/noticeBoard/NoticeBoard';
import Lecturer from './pages/lecturer/Lecturer';
import Student from './pages/student/Student';
import Course from './pages/course/Course';
import Inquiry from './pages/inquiries/Inquiries';
import {createContext, useContext, useEffect} from 'react';
import { io } from 'socket.io-client';
import { SERVER_ROUTE } from './utils/commonConstants';
import TimeTable from './pages/timetable/TimeTable';
import Subject from './pages/subject/Subject';
import AuthContextProvider, {AuthContext} from './context/AuthContextProvider';
import { ConfigProvider, App as AntAppContext } from 'antd';
import Enrollments from './pages/enrollment/Enrollments';
import ManageEnrollmentTab from './pages/enrollment/ManageEnrollmentTab';
import AddStudentForm from './pages/student/tabItems/AddStudentForm';
import Profile from './pages/profile/Profile';
import ManageStudentTab from './pages/student/ManageStudentTab';
import LecturerTab from './pages/lecturer/LecturerTab';
import NoticeBoardView from './components/NoticeBoardView';
import Tasks from './pages/task/Tasks';
import SessionRecord from './pages/lecturer/tabItems/SessionRecord';
import { TinyColor } from '@ctrl/tinycolor';
import Exam from './pages/exam/Exam';
import ManageExamTab from './pages/exam/ManageExamTab';
import Staff from './pages/staff/Staff';
import StaffTab from './pages/staff/StaffTab';
import TransactionsTab from './pages/payroll/PayrollTab';
import ReportTab from './pages/report/ReportTab';
import {userRoles} from "./utils/configConstants";
import Certificate from "./pages/enrollment/certificate/Certificate";
import PublicCertificateView from "./pages/enrollment/certificate/PublicCertificateView";
import Barcodes from "./components/Barcodes";
import Transcripts from './components/Transcripts';

export const SocketContext = createContext(null);

const socket = io(SERVER_ROUTE);
export const routes = [
    {
        path:"/",
        element: <Dashboard />,
        allowedFor: [userRoles.admin, userRoles.editor, userRoles.teacher, userRoles.student]
    },
    {
        path:"/blog",
        element: <Blog />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/filemanager",
        element: <FileManager />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/sms",
        element: <SMS />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/dormitory",
        element: <Dormitory />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/noticeboard",
        element: <NoticeBoard />,
        allowedFor: [userRoles.admin, userRoles.editor, userRoles.teacher, userRoles.student]
    },
    {
        path:"/subjects",
        element: <Subject />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/lecturers",
        element: <Lecturer />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/lecturers/manage",
        element: <LecturerTab />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/session/manage",
        element: <SessionRecord />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/students",
        element: <Student />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/students/manage",
        element: <ManageStudentTab />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/courses",
        element: <Course />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/inquiries",
        element: <Inquiry />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/timetable",
        element: <TimeTable />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/enrollment",
        element: <Enrollments />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/enrollment/manage",
        element: <ManageEnrollmentTab />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/enrollment/certificate",
        element: <Certificate />,
        allowedFor: [userRoles.admin, userRoles.editor, userRoles.student, userRoles.teacher]
    },
    {
        path:"/profile",
        element: <Profile />,
        allowedFor: [userRoles.admin, userRoles.editor, userRoles.teacher, userRoles.student]
    },
    {
        path:"/noticeView",
        element: <NoticeBoardView />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/exam",
        element: <Exam />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/exam/manage",
        element: <ManageExamTab />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/staff",
        element: <Staff />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/staff/manage",
        element: <StaffTab />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/tasks",
        element: <Tasks />,
        allowedFor: [userRoles.admin, userRoles.editor, userRoles.student, userRoles.teacher]
    },
    {
        path:"/payroll",
        element: <TransactionsTab />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/report",
        element: <ReportTab />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/barcodes",
        element: <Barcodes />,
        allowedFor: [userRoles.admin, userRoles.editor]
    },
    {
        path:"/transcripts",
        element: <Transcripts />,
        allowedFor: [userRoles.admin, userRoles.editor]
    }
]
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <div>error 404</div>,
        children: routes
    },
    {
      path:"enrollment/application",
      element: <div className='pop-left p-5 fade md:px-40 shadow-lg rounded-xl bg-white/60' style={{
        // backgroundImage: 'url(https://t3.ftcdn.net/jpg/03/55/60/70/360_F_355607062_zYMS8jaz4SfoykpWz5oViRVKL32IabTP.jpg)',
        // backgroundImage: 'url(https://colibriwp.com/blog/wp-content/uploads/2019/06/pawel-czerwinski-vI5XwPbGvmY-unsplash.jpg)',
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7)),url("https://sms.clcsl.edu.lk/serverclc/upload/website/welcome/carrosal/4.jpg")',
        // backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),url("https://previews.123rf.com/images/aunaauna/aunaauna1501/aunaauna150100075/35874658-textile-texture-background-seamless-pattern-for-web-design-desktop-wallpaper-or-website.jpg")',
        backgroundSize: "contain"
    }}>

        <div className='text-4xl py-10'>Add New Application</div>
        <AddStudentForm />

      </div>
    },
    {
        path:"/certificate",
        element: <PublicCertificateView />
    },
]);

function App() {
  useEffect(() => {
    socket.on("connect", () => {
        console.info('socket instance initiated', socket.id);
        sessionStorage.setItem('socketId', socket.id)
    });

    socket.on("disconnect", () => {
    });
  }, [])

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            // colorPrimary: '#00b96b',
            // borderRadius: 2,

            // Alias Token
            // colorBgContainer: '#f6ffed',
          }
        }}
        // button={{className: 'btn-default'}}
      >
        <AntAppContext>
          <AuthContextProvider>
            <SocketContext.Provider value={socket}>
              <RouterProvider router={router} />
            </SocketContext.Provider>
          </AuthContextProvider>
        </AntAppContext>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
