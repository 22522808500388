import React, { useContext, useState } from 'react';
import { Alert, Button, Form, Input, message } from 'antd';
import axios from 'axios';
import { processReq } from '../helpers/processRequest';
import { GET } from '../utils/requestActionConstants';
import { API_ROUTE, SERVER_ROUTE } from '../utils/commonConstants';
import { AuthContext } from '../context/AuthContextProvider';

const Login = () => {
    const {login, isLoggedIn} = useContext(AuthContext);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async ({email, password}) => {
        setIsLoading(true);
        let result = await login(email, password);

        if (result) {
            setIsError(true);
            setErrorMessage(result);
            message.error(result);
        }
        setIsLoading(false);
    };

    const onFinishFailed = (errorInfo) => {
        // console.log(errorInfo)
        setIsLoading(false);
    };

    const onClose = (e) => {
        setIsError(false);
        setIsLoading(false);
    };

    // const sendGetRequest = async () => {
    //     try {
    //         let server = "https://ceylon-ladies-campus.uc.r.appspot.com";
    //         // server = "http://localhost:3000";

    //         const result = await axios.get(server + '/test-gcloud');

    //         console.log(result.data);
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    // const sendPostRequest = async () => {
    //     try {
    //         let server = "https://ceylon-ladies-campus.uc.r.appspot.com";
    //         // server = "http://localhost:3000";

    //         const result = await axios.post(server + '/test-gcloud');

    //         console.log(result.data);
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // const getPosts = async () => {
    //     const result = await processReq(GET, API_ROUTE + '/posts');

    //     console.log(result)
    // }

    return (
    <div>
        <div className="sticky top-0 z-50 font-semibold text-sm text-slate-900 ring-1 ring-slate-900/10"><div className="flex bg-gradient-to-r bg-slate-50/90 backdrop-blur-sm  shadow-md shadow-slate-200 p-3"><div className="from-inherit align-middle"><a className="flex" href="/"><img src="/static/media/logo.55134aa1b1092bea5cdd.png" width="100" alt="CLC" /> <span className="my-auto pl-2 text-2xl md:text-3xl font-clc ">Ceylon Ladies' Campus</span></a></div><div className="grow"></div><div className="hidden md:flex"><nav className="text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200"><ul className="flex space-x-8 px-8 h-full"><li className="my-auto primary-fore-color"></li></ul></nav><div className="my-auto"><a className="py-2 px-3 bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none" href="https://clcsl.edu.lk">Switch to Web Site</a></div></div><div className="flex md:hidden animate-fadeIn pr-4"><div className="icon-bars my-auto"></div></div></div><div className="animate-slideOut hidden absolute top-20 z-50 right-0 bg-slate-50 w-full"><nav className="px-3 py-3 text-sm leading-6 font-semibold text-slate-700 dark:text-slate-200"><ul className="h-full w-full px-3 py-3"><li className="my-auto"><a href="/">Home</a></li></ul><div className="w-full text-center"><a className="py-2 px-3 w-full bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none" href="/online-application">JOIN NOW!</a></div></nav></div></div>
        <div className='md:flex md:h-full justify-center items-center pb-5' style={{
            // backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7)),url("https://sms.clcsl.edu.lk/serverclc/upload/website/welcome/carrosal/4.jpg")',
            // backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2)),url("https://img.freepik.com/premium-photo/abstract-texture-background-pattern-backdrop-gradient-wallpaper_41691-1380.jpg")',
            // backgroundImage: 'url(https://previews.123rf.com/images/aunaauna/aunaauna1501/aunaauna150100075/35874658-textile-texture-background-seamless-pattern-for-web-design-desktop-wallpaper-or-website.jpg)',
            // backgroundImage: 'url(https://t3.ftcdn.net/jpg/03/55/60/70/360_F_355607062_zYMS8jaz4SfoykpWz5oViRVKL32IabTP.jpg)',
            // backgroundImage: 'url(https://colibriwp.com/blog/wp-content/uploads/2019/06/pawel-czerwinski-vI5XwPbGvmY-unsplash.jpg)',
            backgroundSize: "cover",
        }}>
            <div className='border rounded-xl md:w-1/3 mt-10 mx-3 mb-5 p-5 bg-slate-100'>
                <div className='text-3xl text-center py-10'>Sign In</div>
                <Form
                    name="basic"
                    labelCol={{
                    span: 6,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                    // style={{
                    // maxWidth: 600,
                    // }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className='m-auto'
                >
                    <Form.Item
                        label="User Email"
                        name="email"
                        initialValue={''}
                        rules={[
                            {
                            required: true,
                            message: 'Please input your email!',
                            },
                        ]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        initialValue={''}
                        rules={[
                            {
                            required: true,
                            message: 'Please input your password!',
                            },
                        ]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 6,
                            span: 16,
                        }}
                    >
                    <Button htmlType="submit" type="primary"  className=" bg-blue-500" loading={isLoading}>Sign In</Button>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 16,
                        }}
                    >
                        {isError ?
                            <Alert
                                message={errorMessage}
                                type="warning"
                                closable
                                afterClose={onClose}
                            />
                        : '' }
                    </Form.Item>
                </Form>
            </div>
        </div>

        {/*<div onClick={async () => {*/}
        {/*    const res = await processReq(GET, SERVER_ROUTE + '/test-gcloud');*/}

        {/*    console.log(res);*/}
        {/*}}>----</div>*/}

        {/* <Button onClick={sendPostRequest}>Test Post Request</Button>
        <Button onClick={sendGetRequest}>Test Get Request</Button>
        <Button onClick={getPosts}>Get Posts</Button> */}
    </div>
  );
}

export default Login;
