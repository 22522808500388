import useSWR, { mutate } from "swr";
import { DELETE, GET, POST, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import EditInput from "../../../components/EditInput";
import { App, Button, Form, Input, Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { commonBoolean, messages } from "../../../utils/configConstants";
import { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";

const LecturerSubjects = ({ formData }) => {
    const model = '/auth/lecturerSubject';
    const subjectResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/subject' }, fetcher);
    const lecturerResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/lecturer/dist/' + formData.lecturerId }, fetcher);
    const lecturer = lecturerResponse.data;
    const result = useSWR({ method: GET, url: API_ROUTE + model }, fetcher);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const navigate = useNavigate();

    useEffect(() => {
        resetForm();
    }, [])

    const deleteRecord = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + model + '/del/' + record.id, record);

        if (!response.error) {
            message.success('Record deleted!');
        } else {
            message.error('Deletion failed!');
        }

        lecturerResponse.mutate();
    }

    const addRecord = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedRecord.id) {
            // console.log({...data, id: selectedRecord.id})
            await processReq(PUT, API_ROUTE + model, { ...data, id: selectedRecord.id });
            lecturerResponse.mutate();
            message.success('Updated!')
        } else {
            await processReq(POST, API_ROUTE + model, {...data, lecturerId: lecturer.id});
            lecturerResponse.mutate();
            message.success('Saved!')
        }

        handleCancel();
    }

    const resetForm = () => {
        setSelectedRecord({})
    }

    return (
        <div>
            {
                lecturerResponse.isLoading ? 'Loading' :

                    <div className="p-5 bg-white/60 rounded-xl fade shadow-lg">
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <div className="text-xl pb-3">Lecturer Subjects</div>
                            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Record</Button></div>
                            <div className="flex items-center py-2">
                                <DataTable
                                    columns={[
                                        "id",
                                        "subjectName",
                                    ]}
                                    data={lecturer.subjects}
                                    isLoading={lecturerResponse.isLoading}
                                    deleteAction={deleteRecord}
                                />
                            </div>
                        </div>

                        <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                            <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                                <Form.Item label='Subject' name={'subjectId'} rules={[{required: commonBoolean.required, message: messages.requiredMessage}]}>
                                    <Select options={subjectResponse.data} placeholder="Select Subject"/>
                                </Form.Item>

                                <Form.Item className="text-right">
                                    <Button htmlType="submit">Save Record</Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
            }
        </div>
    )
}

export default LecturerSubjects;