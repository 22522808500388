import { Tabs } from "antd";
import LecturerPaymentReport from "./tabItems/LecturerPaymentReport";

const ReportTab = () => {
    const tabs = [
        {
          key: '1',
          label: 'Lecturer Payment Report',
          children: <LecturerPaymentReport />,
        },
    ];

    const onChange = (key) => {
        // console.log(key);
    };

    return (
        <div>
            <div className="">
                <Tabs defaultActiveKey="1" items={tabs} onChange={onChange} tabBarStyle={{fontWeight: 'bold', backgroundColor: '#e4f6ff', borderRadius: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10, boxShadow: 'rgb(218 229 235) 0px 3px 9px 0px'}} />
            </div>
        </div>
    )
}

export default ReportTab;