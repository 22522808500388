import useSWR from "swr";
import { fetcher, processReq } from "../helpers/processRequest";
import { GET, POST, PUT } from "../utils/requestActionConstants";
import { API_ROUTE } from "../utils/commonConstants";
import EditInput from "./EditInput";
import { userRoles } from "../utils/configConstants";
import { Button } from "antd";
import { useState } from "react";

const UserProfileView = ({ formData }) => {
    // console.log(formData)
    const [userId, setUserId] = useState(formData.user?.id)
    const userResponse = useSWR({ method: GET, url: API_ROUTE + '/auth/user/dist/' + userId }, fetcher);
    const user = userResponse.data ? userResponse.data : {};

    let onUpdate = (value, field, dataObject) => {
        return new Promise(async (resolve, reject) => {
            let error = false;
            let reqObject = dataObject;

            reqObject[field] = value;

            let result = await processReq(PUT, API_ROUTE + '/auth/user', reqObject);

            error = !(result && result.length > 0);

            if (error) {
                reject('Failed')
            } else {
                resolve('success');
                userResponse.mutate();
            }
        })
    }

    const createUserAccount = async () => {
        const reqObject = {
            password: '1234',
            email: formData.email,
            userName: formData.firstName
        }

        switch (formData.userRole) {
            case userRoles.student:
                reqObject.studentId = formData.id;
                reqObject.userRole = userRoles.student;
                break;
            case userRoles.teacher:
                reqObject.lecturerId = formData.id;
                reqObject.userRole = userRoles.teacher;
                break;
            default:

        }

        const response = await processReq(POST, API_ROUTE + '/auth/user', reqObject);

        setUserId(response.id)
        userResponse.mutate();
    }

    return (
        <div>
            <div className="pop-up fade md:p-10 shadow-lg rounded-xl bg-white/60">
                {
                    user.id ?

                        <div>
                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">User Name</div> : <div className="pl-2">
                                    <EditInput
                                        masterData={user.userName}
                                        onSuccess={onUpdate}
                                        field={'userName'}
                                        object={user}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">User Role</div> : <div className="pl-2 grow">
                                    <EditInput
                                        type={'dropdown'}
                                        masterData={user?.userRoleLabel}
                                        label={user?.userRoleLabel}
                                        onSuccess={onUpdate}
                                        dropdownData={userRoles.formData}
                                        field={'userRole'}
                                        object={user}
                                    />
                                </div>
                            </div>


                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Password</div> : <div className="pl-2 grow">
                                    <EditInput
                                        masterData={user.userPass}
                                        onSuccess={onUpdate}
                                        field={'userPass'}
                                        object={user}
                                        htmlType={'password'}
                                        secretLabel={true}
                                        verificationEnabled={true}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center py-2">
                                <div className="font-bold w-1/3">Email</div> : <div className="pl-2">
                                    <EditInput
                                        masterData={user.email}
                                        onSuccess={onUpdate}
                                        field={'email'}
                                        object={user}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <Button onClick={createUserAccount}>Create Account</Button>
                        </div>
                }
            </div>
        </div>
    )
}

export default UserProfileView;
