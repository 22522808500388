import { Alert, App, Button, DatePicker, Form, Input, Select, notification } from "antd";
import { useEffect, useState } from "react";
import { commonBoolean, commonExams, district, enrollmentStatus, examType, messages, mobileNumberRegions, nationality, placeholders, schoolingType, sourceOfInfo } from "../../../utils/configConstants";
import useSWR from "swr";
import { GET, POST, PUT } from "../../../utils/requestActionConstants";
import { API_ROUTE } from "../../../utils/commonConstants";
import { fetcher, processReq } from "../../../helpers/processRequest";
import LottieViewer from "../../../components/LottieViewer";
import { removePlaceholdersAndGetValue } from "../../../utils/formatters";
import { hasDuplicates } from "../../../utils/validations";

const AddStudentForm = ({ closeApplicationView }) => {
    const courses = useSWR({ method: GET, url: API_ROUTE + '/auth/course' }, fetcher);
    const subjects = useSWR({ method: GET, url: API_ROUTE + '/auth/subject' }, fetcher);
    const [api, contextHolder] = notification.useNotification();
    const [student, setStudent] = useState({});
    const [showVerification, setShowVerfication] = useState(false);
    const [applicationSubmited, setApplicationSubmited] = useState(false);
    const [olSubjects, setOlSubjects] = useState([]);
    const [alSubjects, setAlSubjects] = useState([]);
    const [form] = Form.useForm();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [showGCEAlResult, setShowGCEAlResult] = useState(false);
    const { message } = App.useApp();

    const onFinish = async (data) => {
        setShowVerfication(true);
        setApplicationSubmited(false);
        form.getFieldInstance('sourceInfo').focus();
        setStudent(data);

        // console.log(data)
    }

    const verifyAndSubmit = async () => {
        let studentObj = student;

        let result = await processReq(POST, API_ROUTE + '/student', studentObj);

        if (!result.error) {
            studentObj = { ...studentObj, ...result };
            api['success']({ message: 'Application Submitted!' });
            setApplicationSubmited(true);

            // if (student.id) {
            //     await processReq(PUT, API_ROUTE + '/auth/student', {...data, id: student.id});
            //     api['success']({ message: 'Updated!'});
            // } else {
            //     await processReq(POST, API_ROUTE + '/auth/student', data);
            //     api['success']({ message: 'Saved!'});
            // }
        } else {
            setShowVerfication(false);

            let field = result.errorMessage?.split(' ')[0];
            let fieldInstance = form.getFieldInstance(field);
            let errorMessage = result.errorMessage;

            if (fieldInstance) {
                fieldInstance.focus();

                errorMessage = field + " already taken";
            }

            api['error']({ message: errorMessage });
        }
    }

    const onCloseApplicationProcess = () => {
        window.location.replace('http://localhost:3002')
        // setApplicationSubmited(false);
        // setShowVerfication(false);
        // closeApplicationView();
        // form.resetFields();
    }

    const mobileNumberPrefix = (
        <Form.Item name="mobilePrefix" noStyle initialValue={mobileNumberRegions.formData[0].value}>
            <Select
                style={{
                    width: 70,
                }}
                options={mobileNumberRegions.formData}
            />
        </Form.Item>
    );

    const setDefaultFormValues = (_, course) => {
        let GCEOL = course.exams.find(exam => exam.examName === commonExams.gceOl);

        form.setFieldsValue({ gceOlResults: GCEOL.examSubjects });
    }

    const setOlSubjectsData = () => {
        let GCEOL = subjects.data?.filter(subject => subject.type === commonExams.subjectTypeOl);

        setOlSubjects(GCEOL)
    }

    const setAlSubjectsData = () => {
        let GCEAL = subjects.data?.filter(subject => subject.type === commonExams.subjectTypeAl);

        setAlSubjects(GCEAL)
    }

    useEffect(() => {
        setOlSubjectsData();
        setAlSubjectsData();
    }, [subjects.data])

    const onChangeExamMethod = (_, examMethod) => {
        let GCEAL = [{}, {}, {}, {}, {}]

        if (examMethod.value === examType.repeat) {
            setShowGCEAlResult(true)
            form.setFieldsValue({ gceAlResults: GCEAL });
        } else {
            setShowGCEAlResult(false)
            form.setFieldsValue({ gceAlResults: [] });
        }
    }

    return (
        <div className="relative fade">
            {contextHolder}
            <Form form={form} className="p-5 bg-white/60 rounded-xl shadow-lg" onFinish={onFinish} layout="vertical">
                <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                    <div className="text-xl pb-3">Course Details</div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Form.Item label='Course Name' name={'courseName'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Select options={courses.data} placeholder="Select your course" onSelect={setDefaultFormValues} />
                        </Form.Item>
                        <Form.Item label='Schooling Type' name={'schoolingType'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Select options={schoolingType.formData} placeholder="Select your method of schoolin" />
                        </Form.Item>
                    </div>
                </div>

                <div className="text-xl pb-3">Student Details</div>
                <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-5 bg-white/80">


                    <div className="flex gap-4 md:flex-row flex-col">
                        <Form.Item label='Source of Information About CLC' name={'sourceInfo'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Select
                                options={sourceOfInfo.formData}
                            />
                        </Form.Item>
                        <Form.Item label='Nationality' name={'nationality'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Select
                                options={nationality.formData}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item label='First Name' name={'firstName'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                        <Input placeholder="Enter first name" />
                    </Form.Item>
                    <Form.Item label='Last Name' name={'lastName'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                        <Input placeholder="Enter last name" />
                    </Form.Item>
                    <Form.Item label='Permanent Address' name={'permAddress'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                        <Input placeholder="Enter permanent address" />
                    </Form.Item>
                    <Form.Item label='Postal Address' name={'postAddress'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                        <Input placeholder="Enter postal address" />
                    </Form.Item>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Form.Item label='Date of Birth' name={'dob'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <DatePicker placeholder="DOB" />
                        </Form.Item>
                        <Form.Item label='Email' name={'email'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Input placeholder="Enter your email" type="email" />
                        </Form.Item>
                        <Form.Item label='Mobile Number (SMS and WhatsApp)' name={'mobNumber'} rules={[{ required: commonBoolean.required, message: messages.invalidMobile, pattern: '^[1-9][0-9]*$' }]}>
                            <Input addonBefore={mobileNumberPrefix} placeholder="Eg: 777277234" />
                        </Form.Item>
                        <Form.Item label='National Identity Card Number' name={'nic'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Input placeholder="Enter your NIC" />
                        </Form.Item>
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Form.Item label='City' name={'city'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Input placeholder="Enter city" />
                        </Form.Item>
                        <Form.Item label='District' name={'district'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Select options={district.formData} placeholder='Select district' />
                        </Form.Item>
                    </div>
                    <Form.Item label='Student Illness Description (If Any)' name={'stdIllnessDesc'}>
                        <Input placeholder="Enter student illness description here" />
                    </Form.Item>
                    <Form.Item label='Any comments from the applicant' name={'stdRemarks'}>
                        <Input placeholder="Enter your remarks here" />
                    </Form.Item>

                    <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                        <div className="text-xl pb-3">Exam Details</div>
                        <div className="text-lg pb-3">GCE Ordinary Level</div>
                        <div class="grid md:grid-cols-2 gap-4">
                            <Form.List
                                name="gceOlResults"
                                rules={[
                                    {
                                        validator: async (_, gceOlResults) => {
                                            if (!gceOlResults || gceOlResults.length < 6) {
                                                let msg = 'At least 6 O/L subjects are required'
                                                setError(true)
                                                setErrorMessage(msg)
                                                message.error(msg)
                                                return Promise.reject(new Error(msg));
                                            } else if (gceOlResults.length > 9) {
                                                let msg = 'A maximum of 9 O/L subjects are allowed'
                                                setError(true)
                                                setErrorMessage(msg)
                                                message.error(msg)
                                                return Promise.reject(new Error(msg));
                                            } else if (hasDuplicates(gceOlResults, 'value')) {
                                                let msg = 'Duplicate O/L subject entries not allowed'
                                                setError(true)
                                                setErrorMessage(msg)
                                                message.error(msg)
                                                return Promise.reject(new Error(msg));
                                            } else {
                                                setError(false);
                                                setErrorMessage("")
                                            }
                                        }
                                    }
                                ]}

                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div className="flex" key={key}>
                                                <div className="grow">
                                                    <Form.Item {...restField} name={[name, 'value']} rules={[
                                                        { required: commonBoolean.required, message: messages.requiredMessage }
                                                    ]}>
                                                        <Select
                                                            options={olSubjects}
                                                            showSearch
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'marks']}
                                                        rules={[
                                                            {
                                                                required: commonBoolean.required,
                                                                message: messages.requiredMessage,
                                                            }
                                                        ]}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <Input placeholder="Marks/Grade" />
                                                    </Form.Item>
                                                </div>
                                                <Button className="ml-2" type="dashed" danger onClick={() => remove(name)}>
                                                    -
                                                </Button>
                                            </div>
                                        ))}
                                        {/* <Form.ErrorList errors={errors} /> */}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()}>
                                                +
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}

                            </Form.List>
                        </div>
                        {error && <Alert message={errorMessage} type="error" />}

                    </div>

                    <div className="flex gap-4 md:flex-row flex-col">
                        <Form.Item label='Exam method' tooltip="If you are a Repeat student, set this to repeat and submit last GCE A/L Results" name={'examType'} rules={[{ required: commonBoolean.required, message: messages.requiredMessage }]}>
                            <Select
                                options={examType.formData}
                                onChange={onChangeExamMethod}
                            />
                        </Form.Item>
                    </div>

                    {showGCEAlResult && (
                        <div className="border-dashed border-2 rounded-lg p-4 shadow-lg mb-4 bg-white/80">
                            <div className="text-lg pb-3">GCE Advanced Level (Last Attempt)</div>
                            <div class="grid md:grid-cols-2 gap-4">
                                <Form.List
                                    name="gceAlResults"
                                    rules={[
                                        {
                                            validator: async (_, gceAlResults) => {
                                                if (hasDuplicates(gceAlResults, 'value')) {
                                                    let msg = 'Duplicate A/L subject entries are not allowed'
                                                    setError(true)
                                                    setErrorMessage(msg)
                                                    message.error(msg)
                                                    return Promise.reject(new Error(msg));
                                                } else {
                                                    setError(false);
                                                    setErrorMessage("")
                                                }
                                            }
                                        }
                                    ]}

                                >
                                    {(fields) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <div className="flex" key={key}>
                                                    <div className="grow">
                                                        <Form.Item {...restField} name={[name, 'value']} rules={[
                                                            { required: commonBoolean.required, message: messages.requiredMessage }
                                                        ]}>
                                                            <Select
                                                                options={alSubjects}
                                                                showSearch
                                                                placeholder="Search to Select"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                                filterSort={(optionA, optionB) =>
                                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                }
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'marks']}
                                                            rules={[
                                                                {
                                                                    required: commonBoolean.required,
                                                                    message: messages.requiredMessage,
                                                                }
                                                            ]}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <Input placeholder="Marks/Grade" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}

                                </Form.List>
                            </div>
                            {error && <Alert message={errorMessage} type="error" />}

                        </div>
                    )}
                </div>

                <Form.Item className="text-center">
                    <Button htmlType="submit" className=" bg-blue-500" type="primary">Submit</Button>
                </Form.Item>
            </Form>
            {
                showVerification ?
                    <div className="absolute top-0 w-full bg-white rounded-lg h-full p-10 pop-right">
                        {
                            applicationSubmited ?
                                <>
                                    <div className="flex flex-col justify-center items-center w-3/4 m-auto h-1/4">
                                        <div className="text-4xl text-green-700 pb-10 text-center">Your application has been submited!</div>
                                        <div className="text-xl text-center pb-10">You will recieve a confirmation email and an SMS messsage to the provided email and mobile number</div>
                                        <LottieViewer lottieName={'particles'} />
                                        <LottieViewer lottieName={'successCheck'} loop={false} />
                                        <Button type="primary" className={'bg-blue-500'} onClick={onCloseApplicationProcess}>Back to Home</Button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="border-dashed border-2 rounded-lg p-4 mb-5">
                                        <div className="text-2xl pb-3">Verification</div>
                                        {
                                            Object.keys(form.getFieldsValue()).map((field, index) => {
                                                let key = field.replace(/([A-Z])/g, " $1") ? field.replace(/([A-Z])/g, " $1").toUpperCase() : 'N/A';
                                                let value = Object.values(form.getFieldsValue())[index] ? Object.values(form.getFieldsValue())[index] : 'N/A';
                                                let plainText = value.toString();

                                                if (['gceOlResults', 'gceAlResults'].some(res => res === field)) {
                                                    return <></>
                                                }
                                                return (
                                                    <div className="flex gap-4 md:flex-row flex-col border-b py-1" key={index}>
                                                        <div className="font-bold w-1/3">{key} </div> <div>: {removePlaceholdersAndGetValue(plainText, placeholders.dropdownDataPlaceholder)}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div className="flex gap-4 md:flex-row flex-col border-b py-1 bg-slate-300 my-5">
                                            <div className="font-bold w-1/3">GCE O/L Results</div>
                                        </div>
                                        {
                                            form.getFieldValue('gceOlResults')?.map((result, index) => {
                                                return (
                                                    <div className="flex gap-4 md:flex-row flex-col border-b py-1" key={index}>
                                                        <div className="font-bold w-1/3">{removePlaceholdersAndGetValue(result.value, placeholders.dropdownDataPlaceholder)} </div> <div>: {result.marks}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div className="flex gap-4 md:flex-row flex-col border-b py-1 bg-slate-300 my-5">
                                            <div className="font-bold w-1/3">GCE A/L Results</div>
                                        </div>
                                        {
                                            form.getFieldValue('gceAlResults')?.map((result, index) => {
                                                return (
                                                    <div className="flex gap-4 md:flex-row flex-col border-b py-1" key={index}>
                                                        <div className="font-bold w-1/3">{removePlaceholdersAndGetValue(result.value, placeholders.dropdownDataPlaceholder)} </div> <div>: {result.marks}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <Button onClick={() => setShowVerfication(false)} className="mr-5">Edit</Button>
                                    <Button type="primary" className="bg-blue-500" onClick={verifyAndSubmit}>Verify & Submit Application</Button>
                                </>
                        }
                    </div>
                    : ''
            }

        </div>
    )
}

export default AddStudentForm;
