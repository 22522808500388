import { Button, DatePicker, Form, Input, Modal, Select, notification } from "antd";
import DataTable from "../../components/DataTable";
import { fetcher, processReq } from "../../helpers/processRequest";
import { API_ROUTE } from "../../utils/commonConstants";
import { DELETE, GET, POST, PUT } from "../../utils/requestActionConstants";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { courseStatus, messages } from "../../utils/configConstants";
import dayjs from "dayjs";

const Course = () => {
    const courses = useSWR({method: GET, url: API_ROUTE + '/auth/course'} , fetcher);
    const subjects = useSWR({method: GET, url: API_ROUTE + '/auth/subject'} , fetcher);
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [form] = Form.useForm();

    useEffect(() => {
        resetForm();
    }, [])

    const deleteCourse = async (record) => {
        const response = await processReq(DELETE, API_ROUTE + '/auth/course/del/' + record.id, record);

        if (!response.error) {
            api['success']({ message: 'Course deleted!', description: ''});
        } else {
            api['error']({ message: 'Deletion failed!', description: ''});
        }

        courses.mutate();
    }

    const editCourse = (record) => {
        let object = {
            ...record,
            courseStatus: courseStatus.getLabel(record.courseStatus),
            courseStartYear: dayjs(record.courseStartYear),
            courseEndYear: dayjs(record.courseEndYear),
            courseStartDate: dayjs(record.courseStartDate),
            courseEndDate: dayjs(record.courseEndDate),

        }

        setSelectedCourse(object);
        form.setFieldsValue(object);
        setIsModalOpen(true);
    }

    const addRecord = () => {
        form.resetFields();
        resetForm();
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (data) => {
        if (selectedCourse.id) {
            // console.log({...data, id: selectedCourse.id})
            await processReq(PUT, API_ROUTE + '/auth/course', {...data, id: selectedCourse.id});
            courses.mutate();
            api['success']({ message: 'Updated!'});
        } else {
            await processReq(POST, API_ROUTE + '/auth/course', data);
            courses.mutate();
            api['success']({ message: 'Saved!'});
        }

        handleCancel();
    }

    const resetForm = () => {
        setSelectedCourse({})
    }

    return (
        <div className="pop-left fade md:p-10 shadow-lg rounded-xl bg-white/60">
            {contextHolder}
            <div className="text-2xl">Course Management</div>
            <div className="text-3xl pb-5 text-right"><Button onClick={addRecord}>Add Course</Button></div>
            <div>
                <DataTable
                    columns={[
                    "id",
                    "courseName"
                    ]}
                    data={courses.data}
                    isLoading={courses.isLoading}
                    deleteAction={deleteCourse}
                    editAction={editCourse}
                />
            </div>


            <Modal width={'100vw'} maskClosable={false} title="Add New Course" open={isModalOpen} onCancel={handleCancel} okButtonProps={{hidden: true}} cancelButtonProps={{hidden: true}}>
                <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical">
                    <Form.Item label='Course Status' name={'courseStatus'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Select
                            value={selectedCourse.courseStatus}
                            onChange={(dt) => setSelectedCourse({...selectedCourse, courseStatus: dt})}
                            options={courseStatus.formData}
                        />
                    </Form.Item>
                    <Form.Item label='Course Name' name={'courseName'} rules={[{required: true, message: messages.requiredMessage}]}>
                        <Input placeholder="Enter course name"/>
                    </Form.Item>
                    <div className="flex gap-4">
                        <Form.Item label='Course Start Date' name={'courseStartDate'}>
                            <DatePicker />
                        </Form.Item>
                        <Form.Item label='Course End Date' name={'courseEndDate'}>
                            <DatePicker />
                        </Form.Item>
                    </div>
                    <div className="flex gap-4">
                        <Form.Item label='Course Start Year' name={'courseStartYear'} rules={[{required: true, message: messages.requiredMessage}]}>
                            <DatePicker picker="year"/>
                        </Form.Item>
                        <Form.Item label='Course End Year' name={'courseEndYear'} rules={[{required: true, message: messages.requiredMessage}]}>
                            <DatePicker picker="year"/>
                        </Form.Item>
                    </div>
                    <div className="flex gap-4">
                        <Form.Item label='Admission Fee' name={'admissionFee'}>
                            <Input placeholder="Enter admission fee" type="number"/>
                        </Form.Item>
                        <Form.Item label='Class Fee' name={'classFee'}>
                            <Input placeholder="Enter monthly class fee" type="number"/>
                        </Form.Item>
                        <Form.Item label='Meals Fee' name={'mealsFee'}>
                            <Input placeholder="Enter monthly meals fee" type="number"/>
                        </Form.Item>
                        <Form.Item label='Accomodation Fee' name={'accomodationFee'}>
                            <Input placeholder="Enter monthly accomodation fee" type="number"/>
                        </Form.Item>
                    </div>
                    <Form.List name="subjects">
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(({ key, name, ...restField }) => (
                                <div className="flex" key={key}>
                                    <div className="grow">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'subjectName']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Subject Name Cannot Be Empty',
                                                }
                                            ]}
                                            style={{width: '100%'}}
                                        >
                                            <Select
                                                // value={selectedCourse.courseStatus}
                                                // onChange={(dt) => setSelectedCourse({...selectedCourse, courseStatus: dt})}
                                                options={subjects.data}
                                            />
                                            {/* <Input placeholder="Image Url" style={{width: '100%'}} /> */}
                                        </Form.Item>
                                    </div>
                                    <div className="cursor-pointer pl-5" onClick={() => remove(name)}>Delete</div>
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} style={{width: '100%'}}>
                                    Add New Subject
                                </Button>
                            </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <Form.Item className="text-right">
                        <Button htmlType="submit">Save Course</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Course;
