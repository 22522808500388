import useSWR from "swr";
import Button from "../../components/Button";
import { fetcher } from "../../helpers/processRequest";
import { API_ROUTE } from "../../utils/commonConstants";
import { GET } from "../../utils/requestActionConstants";
import { useNavigate } from "react-router-dom";
import { enrollmentStatus } from "../../utils/configConstants";
import {useContext, useEffect, useState} from "react";
import Marquee from "react-fast-marquee";
import {AuthContext} from "../../context/AuthContextProvider";

const StudentDashboard = () => {
    const {user} = useContext(AuthContext)
    const navigate = useNavigate();
    const studentResponse = useSWR({method: GET, url: API_ROUTE + '/auth/student/dist/' + user.studentId} , fetcher);
    const [noticeString, setNoticeString] = useState('');
    const result = useSWR({ method: GET, url: API_ROUTE + '/auth/notice' }, fetcher);
    const student = studentResponse.data || {};


    useEffect(() => {
        handleNoticeUpdate();
    }, [result.data])

    const handleNoticeUpdate = () => {
        const noticesToShow = [];

        result.data?.filter((notice) => {
            if (notice.isVisible) {
                noticesToShow.push(notice.content);
            }
        })

        setNoticeString(noticesToShow.reverse().join(' - '));
    }

    return (
        <div className="pop-left fade px-5 md:pt-2 md:pb-10 shadow-lg rounded-xl bg-white/60">
            <div className="gradient-bg-1 rounded-xl py-2 my-5 text-lg overflow-hidden px-5"><Marquee speed={100} style={{overflow: 'hidden'}}>{noticeString}</Marquee></div>
            <div className="text-3xl pb-5 text-center">Welcome, {student.fullName}!</div>
            <div className="grid gap-10 md:grid-cols-1 pb-10">
                <div className="p-4 py-8 rounded-xl shadow-xl text-center bg-blue-400 gradient-bg-3">
                    <div className="text-xl">Courses Enrolled</div>
                    <div className="text-3xl">{student.enrollments?.length}</div>
                    <div className="container mx-auto px-4 py-8">
                        <div className="overflow-x-auto">
                            <table className="min-w-full">
                                <tbody>
                                    {
                                        student.enrollments?.map(enrollment => {
                                            return (
                                                <tr key={enrollment.id} className=" bg-white/30 rounded-2xl">
                                                    <td className="text-left py-3 px-4 text-gray-700">{enrollment.course.courseName}</td>
                                                    <td className="text-right py-3 px-4 text-sm text-gray-700"><Button onClick={() => navigate('/enrollment/certificate?dist=' + enrollment.enrollmentId)} style="success">View Certificate</Button></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentDashboard;
