import { useContext } from "react";
import { AuthContext } from "../../context/AuthContextProvider";
import { userRoles } from "../../utils/configConstants";
import EditInput from "../../components/EditInput";
import { processReq } from "../../helpers/processRequest";
import { PUT } from "../../utils/requestActionConstants";
import { API_ROUTE } from "../../utils/commonConstants";

const Profile = () => {
    const {user, verifyUser} = useContext(AuthContext);

    let onUpdate = (value, field, dataObject) => {
        return new Promise(async (resolve, reject) => {
            let error = false;
            let reqObject = dataObject;

            reqObject[field] = value;

            let result = await processReq(PUT, API_ROUTE + '/auth/user', reqObject);

            error = !(result && result.length > 0);

            if (error) {
                reject('Failed')
            } else {
                resolve('success');
                verifyUser();
            }
        })
    }

    return (
        <div>
            <div className="pop-up fade md:p-10 shadow-lg rounded-xl bg-white/60">
                <div>User Name: {user.userName}</div>
                <EditInput
                    masterData={user.userName} 
                    onSuccess={onUpdate} 
                    field={'userName'}
                    object={user}
                />
                <div>Password: {user.userPass}</div>
                <EditInput
                    masterData={user.userPass} 
                    onSuccess={onUpdate} 
                    field={'userPass'}
                    object={user}
                    htmlType={'password'}
                    secretLabel={true}
                    verificationEnabled={true}
                />
                <div>Email: {user.email}</div>
                <div>User Role: {userRoles.getLabel(user.userRole)}</div>
            </div>
        </div>
    )
}

export default Profile;