import { useLocation, useNavigate } from "react-router-dom";
import AddStudentForm from "../student/tabItems/AddStudentForm";
import { Tabs } from "antd";
import EnrollmentForm from "./tabItems/EnrollmentForm";
import ContactDetails from "../student/tabItems/ContactDetails";
import ManageStudent from "../student/tabItems/ManageStudent";
import Payments from "./tabItems/Payments";

const ManageEnrollment = () => {
    const location = useLocation();
    const enrollment = location.state;
    const navigate = useNavigate();

    const tabs = [
        {
            key: '1',
            label: 'Enrollment',
            children: <EnrollmentForm formData={enrollment}/>,
          },
        {
          key: '2',
          label: 'Student',
          children: <ManageStudent formData={enrollment} />,
        },
        {
          key: '3',
          label: 'Student Contact Details',
          children: <ContactDetails formData={enrollment}/>,
        },
        // {
        //     key: '4',
        //     label: 'Parents',
        //     children: 'Content of Tab Pane 3',
        //   },
          {
            key: '5',
            label: 'Payments',
            children: <Payments formData={enrollment}/>,
          },
    ];

    const onChange = (key) => {
        // console.log(key);
    };

    return (
        <div>
            <div className="">
                <div onClick={() => navigate('/enrollment')} className="p-3 rounded-lg bg-cyan-50 w-10 h-10 mb-2 cursor-pointer hover:scale-110 shadow duration-200 ease-in-out"><div className="icon-arrow-left"></div></div>
                <Tabs defaultActiveKey="1" items={tabs} onChange={onChange} tabBarStyle={{fontWeight: 'bold', backgroundColor: '#e4f6ff', borderRadius: 10, paddingLeft: 20, paddingRight: 20, paddingBottom: 10, boxShadow: 'rgb(218 229 235) 0px 3px 9px 0px'}} />
            </div>
        </div>
    )
}

export default ManageEnrollment;