import { App, Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { commonBoolean, messages } from "../../utils/configConstants";
import { processReq } from "../../helpers/processRequest";
import { API_ROUTE } from "../../utils/commonConstants";
import { POST } from "../../utils/requestActionConstants";
import TextArea from "antd/es/input/TextArea";

const SMSSender = ({isOpen, data}) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [form] = Form.useForm();
    const { message } = App.useApp();

    useEffect(() => {
        form.resetFields();
        resetForm();
        setIsModalOpen(isOpen)
        // console.log('hey')
    }, [isOpen])

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const resetForm = () => {
        setSelectedRecord({});
    };

    const onFinish = async (data) => {
        let reqObj = {
            fileData: [{
                mobile: data.mobile,
                message: data.message,
            }],
            count: 1,
            // socketId: socketId
        }
        await processReq(POST, API_ROUTE + '/auth/sms/send', reqObj);
        message.success('Saved!')

        handleCancel();
    }

    return (
        <>
        <div>
        <Modal maskClosable={false} title="Add New Record" open={isModalOpen} onCancel={handleCancel} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
                            <Form form={form} className="pt-5" onFinish={onFinish} layout="vertical" initialValues={{ sendTo: data?.mobile || 0 }}>
                                <Form.Item label='Mobile Number' name={'mobile'} rules={[{required: commonBoolean.required, message: messages.invalidMobile, pattern: '^[1-9][0-9]*$'}]}>
                                    <Input size='large' placeholder="Enter Number" type="number" />
                                </Form.Item>
                                <Form.Item label='Message' name={'message'} rules={[{ required: true, message: messages.requiredMessage }]}>
                                    <TextArea placeholder="Message"/>
                                </Form.Item>

                                <Form.Item className="text-right">
                                    <Button htmlType="submit">Send SMS</Button>
                                </Form.Item>
                            </Form>
                        </Modal>
        </div>
        </>
    )
}

export default SMSSender;
